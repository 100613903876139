export const ARROW_LEFT = 'ArrowLeft';
export const ARROW_RIGHT = 'ArrowRight';
export const SHIFTABLE_KEYS = ['Tab'];
export const CLOSE_KEYS = ['Escape'];
export const DOWN_ARROW_KEYS = ['ArrowDown'];
export const DOWN_KEYS = [...DOWN_ARROW_KEYS, ...SHIFTABLE_KEYS];
export const SELECTION_KEYS = ['Enter'];
export const HORIZONTAL_ARROW_KEYS = [ARROW_LEFT, ARROW_RIGHT];
export const UP_ARROW_KEYS = ['ArrowUp'];
export const VERTICAL_ARROW_KEYS = [...DOWN_ARROW_KEYS, ...UP_ARROW_KEYS];
export const CAPTURABLE_KEYS = [
  ...CLOSE_KEYS,
  ...HORIZONTAL_ARROW_KEYS,
  ...SELECTION_KEYS,
  ...SHIFTABLE_KEYS,
  ...VERTICAL_ARROW_KEYS,
];

export const ACTIVE_SELECTOR = 'active';
export const HIDDEN_SELECTOR = 'hidden';

export function deactivate(element) {
  element?.classList?.remove(ACTIVE_SELECTOR);
}

export function isActive(element) {
  return element?.classList?.contains(ACTIVE_SELECTOR) || false;
}

export function isCapturable(key) {
  return CAPTURABLE_KEYS.includes(key);
}

export function isCloseKey(key) {
  return CLOSE_KEYS.includes(key);
}

export function isDownKey(key) {
  return DOWN_KEYS.includes(key);
}

export function isHidden(element) {
  return element?.classList?.contains(HIDDEN_SELECTOR) || false;
}

export function isHorizontalArrowKey(key) {
  return HORIZONTAL_ARROW_KEYS.includes(key);
}

export function isNavigable(element) {
  return element && !element?.classList?.contains(HIDDEN_SELECTOR);
}

export function isSelectionKey(key) {
  return SELECTION_KEYS.includes(key);
}

export function isShiftableKey(key) {
  return SHIFTABLE_KEYS.includes(key);
}

export function isVerticalArrowKey(key) {
  return VERTICAL_ARROW_KEYS.includes(key);
}

export function isVerticalMovementKey(key) {
  return isVerticalArrowKey(key) || isShiftableKey(key);
}

export function updateElementFocus(element) {
  if (isNavigable(element)) {
    element?.classList?.add(ACTIVE_SELECTOR);
    element?.focus();
  }
}

export default {
  ACTIVE_SELECTOR,
  HIDDEN_SELECTOR,
  ARROW_LEFT,
  ARROW_RIGHT,
  SHIFTABLE_KEYS,
  CLOSE_KEYS,
  DOWN_ARROW_KEYS,
  DOWN_KEYS,
  SELECTION_KEYS,
  HORIZONTAL_ARROW_KEYS,
  UP_ARROW_KEYS,
  VERTICAL_ARROW_KEYS,
  CAPTURABLE_KEYS,
  deactivate,
  isActive,
  isCapturable,
  isCloseKey,
  isDownKey,
  isHidden,
  isHorizontalArrowKey,
  isNavigable,
  isSelectionKey,
  isShiftableKey,
  isVerticalArrowKey,
  isVerticalMovementKey,
  updateElementFocus,
};
