import { action } from '@ember/object';
import { service } from '@ember/service';
import { setControllerFullscreenStatus } from 'client-portal/utils/widget';
import { waitForRender } from 'ember-simplepractice/utils/waiters';
import FastbootHelpersMixin from 'client-portal/mixins/route/fastboot-helpers';
import MessageListenerMixin from './-request/-mixins/message-listener';
import Route from '@ember/routing/route';
import classic from 'ember-classic-decorator';

@classic
export default class RequestRoute extends Route.extend(FastbootHelpersMixin, MessageListenerMixin) {
  @service store;
  @service media;
  @service request;
  @service session;
  @service currentPractice;
  @service analytics;
  @service router;
  @service iframe;
  @service widgetSteps;
  @service mixpanel;
  @service pendingAppointment;

  queryParams = {
    practiceId: { refreshModel: true },
    clinicianId: { refreshModel: true },
    searchByOffice: { refreshModel: true },
    officeId: { refreshModel: true },
    referenceId: { refreshModel: true },
    coveredSessions: { refreshModel: true },
    appearance: { refreshModel: true },
    channel: { refreshModel: true },
    firstName: { refreshModel: true },
    lastName: { refreshModel: true },
    email: { refreshModel: true },
    phoneNumber: { refreshModel: true },
    birthDate: { refreshModel: true },
    otherFirstName: { refreshModel: true },
    otherLastName: { refreshModel: true },
    otherEmail: { refreshModel: true },
    otherPhoneNumber: { refreshModel: true },
    otherBirthDate: { refreshModel: true },
    cptCodeId: { refreshModel: true },
    selectedSlot: { refreshModel: true },
    clientType: { refreshModel: true },
    trackingSource: {},
    addMessage: {},
    reorderedNav: {},
    addReasonsForVisit: {},
    addReasonsForVisitFlag: {},
    anonymousId: {},
  };

  async model(params) {
    let practice = this.currentPractice.get('content');

    let clinician, channel, office, cptCode;

    if (this.session.channel) {
      channel = await this.findChannel();
    } else if (params.channel) {
      this.session.setProperties({ channel: params.channel });
      channel = await this.findChannel();
    }

    if (params.searchByOffice && params.officeId) {
      office = await this.findOffice(params);
    }

    if (params.clinicianId) {
      clinician = await this.findClinician(params, office);
    }

    if (params.cptCodeId) {
      cptCode = await this.findCptCode(params, channel);
    }

    return this.getReservation({ practice, clinician, channel, office, cptCode });
  }

  afterModel(model, transition) {
    let isFirst = model && !this.currentModel;
    super.afterModel(...arguments);
    this.loading(transition);

    if (isFirst) {
      this.mixpanel.track(`user: ${this.request.trackingPrefix} Preload`);
    }
  }

  setupController(controller) {
    super.setupController(...arguments);

    setControllerFullscreenStatus(controller, true);
    controller.set('updateNavigationSteps', this.updateNavigationSteps);

    this.updateNavigationSteps();
  }

  resetController(controller, isExiting, _transition) {
    super.resetController(...arguments);

    if (isExiting) {
      this.set('currentModel', undefined);
      controller.set('fullScreen', undefined);
      controller.set('activeSteps', undefined);
      controller.set('contentLoading', undefined);
      controller.set('preselectionLoading', undefined);
      controller.set('updateNavigationSteps', undefined);
    }
  }

  @action
  updateNavigationSteps() {
    let controller = this.controllerFor(this.request.baseRouteName);
    let activeSteps = this.widgetSteps.findActiveSteps(this.currentModel, controller, this.session);
    controller.set('activeSteps', activeSteps);
  }

  @action
  loading(transition) {
    let routeName = transition.targetName.replace(`${this.request.baseRouteName}.`, '');
    let step = this.widgetSteps.steps.findBy('route', routeName);
    if (!step) {
      return false;
    }

    let controller = this.controllerFor(this.request.baseRouteName);
    controller.set('contentLoading', step.get('loadingTitle'));

    this.widgetSteps.invalidateActiveStep(controller, step.route);
    // eslint-disable-next-line promise/prefer-await-to-then
    transition.promise.finally(() => {
      controller.set('contentLoading', false);
    });

    return true;
  }

  @action
  preselectionLoading() {
    let controller = this.controllerFor(this.request.baseRouteName);
    controller.set('preselectionLoading', 'information');
  }

  @action
  stopPreselectionLoading(transition) {
    let controller = this.controllerFor(this.request.baseRouteName);

    // eslint-disable-next-line promise/prefer-await-to-then
    transition.promise.finally(() => {
      controller.set('preselectionLoading', false);
    });
  }

  @action
  error(err) {
    // currentModel is due to be refreshed
    if (this.currentModel && this.currentModel.isDeleted) {
      return;
    }

    console.error(err); // eslint-disable-line no-console

    let report = window._bugsnagClient && window._bugsnagClient.notify(err);
    let reason = report ? `bugsnag:${report.eventId()}` : err.message;
    this.request.transitionTo(this, 'unavailable', { queryParams: { reason } });
  }

  @action
  reveal() {
    // eslint-disable-next-line no-console
    console && console.log('SPWidget: rect', document.body.getBoundingClientRect());
    this.mixpanel.track(`user: ${this.request.trackingPrefix} Start`);
  }

  @action
  setExternalParams(params) {
    let routeName = this.router.currentRouteName.replace(`${this.request.baseRouteName}.`, '');
    let { clinicianId, cptCodeId, officeId, selectedSlot, clientType } = params;
    this.request.transitionTo(this, routeName, {
      queryParams: { clinicianId, cptCodeId, officeId, selectedSlot, clientType },
    });
  }

  @action
  close(eventProperties) {
    this.mixpanel.trackWidgetEvents('closed', eventProperties);
    this.iframe.close();
  }

  @action
  async didTransition() {
    await waitForRender();
    this.iframe.startHeightBroadcast();
    this.widgetSteps.invalidateActiveStep(
      // eslint-disable-next-line ember/no-controller-access-in-routes
      this.controller,
      this.router.currentRouteName.replace(`${this.request.baseRouteName}.`, '')
    );

    if (this.get('media.isMdDown')) {
      this.scrollTop();
    }
    return true;
  }

  async findOffice(params) {
    if (this.hasShoebox) {
      return this.store.peekRecord('office', params.officeId);
    } else {
      let offices = await this.store.query('office', { id: params.officeId });
      return offices.firstObject;
    }
  }

  async findCptCode(params, channel) {
    let { officeId, clinicianId, cptCodeId, searchByOffice } = params;
    let filter = { clinicianId };
    if (searchByOffice && officeId) {
      filter = { ...filter, officeId };
    }

    if (channel) {
      let globalMonarchChannelId = channel.id;
      filter = { ...filter, globalMonarchChannelId };
    }

    let cptCodes = await this.store.query('cpt-code', { filter });
    return cptCodes.findBy('id', cptCodeId);
  }

  async findClinician(params, office) {
    if (this.hasShoebox) {
      return this.store.peekRecord('clinician', params.clinicianId);
    } else if (office) {
      let clinicians = await this.store.query('clinician', {
        id: params.clinicianId,
        filter: { officeId: office.id },
      });
      return clinicians.firstObject;
    } else {
      return await this.store.findRecord('clinician', params.clinicianId);
    }
  }

  getReservation() {
    if (this.currentModel && !this.currentModel.isDeleted) {
      return this.currentModel;
    }

    let reservation = this.store.peekAll('reservation').rejectBy('isDeleted').get('firstObject');

    return reservation || this.store.createRecord('reservation', ...arguments);
  }

  async findChannel() {
    if (this.hasShoebox) {
      if (!this.session.channel) return null;

      return this.store
        .peekAll('global-monarch-channel')
        .findBy('name', this.session.channel.split('_')[0]);
    } else {
      let channels = await this.store.query('global-monarch-channel', {
        filter: { subchannelName: this.session.channel },
      });
      return channels.firstObject;
    }
  }

  scrollTop() {
    this.iframe.scrollTop(0);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
