import { action } from '@ember/object';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import ConversationListItem from './conversation-list-item.gjs';
import MessagingPage from './messaging-page.gjs';
import SharedInfinityLoader from 'ember-simplepractice/components/shared/infinity-loader';
import styles from './conversation-list-page.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

export default class ConversationListPage extends Component {
  @service messaging;
  @service intl;

  get headerText() {
    return this.intl.t('navigation.conversation_list', {
      amount: this.messaging?.currentProfile?.unreadConversationIds?.length,
    });
  }

  @action
  loadMoreConversations(model) {
    this.messaging.loadMoreConversations.perform(model);
  }

  <template>
    <MessagingPage class="{{styles.component}} conversation-list-page" @isShown={{true}}>
      <:header>
        <div class="navigation-bar" data-test-navigation-bar>
          {{svgJar "messaging-locked" class="locked-icon"}}
          <h3 class="navigation-text" data-test-header>{{this.headerText}}</h3>
        </div>
      </:header>
      <:default>
        {{#if this.messaging.conversations}}
          <ul class="conversation-list-body" data-test-conversation-list-body>
            {{#each this.messaging.conversations as |conversation|}}
              <ConversationListItem @conversation={{conversation}} />
            {{else}}
              <span class="loading">Loading...</span>
            {{/each}}

            <SharedInfinityLoader
              @model={{this.messaging.conversations}}
              @infinityLoad={{this.loadMoreConversations}}
            />
          </ul>
        {{else}}
          <div class="empty-conversation-list" data-test-empty-conversation-list>
            {{svgJar "messaging-welcome"}}
            <h3 class="empty-list-header" data-test-empty-header>{{t "empty_list_header"}}</h3>
            <p class="empty-list-text" data-test-empty-text>{{t "empty_list_text"}}</p>
            {{! Create message button goes here }}
          </div>
        {{/if}}
      </:default>
    </MessagingPage>
  </template>
}
