/* import __COLOCATED_TEMPLATE__ from './gender-dropdown.hbs'; */
import Component from '@glimmer/component';

export default class ContactInformationGenderDropdown extends Component {
  options = [
    'Man (cisgender)',
    'Woman (cisgender)',
    'Agender',
    'Gender fluid',
    'Gender nonconforming',
    'Non-Binary',
    'Queer',
    'Trans man',
    'Trans woman',
    'Two-spirit',
    'Other',
    'Prefer Not To Say',
  ];
}
