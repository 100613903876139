import { buildValidations, validator } from 'ember-cp-validations';
import { computed } from '@ember/object';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { observes } from '@ember-decorators/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import BillingItemMixin from 'client-portal/mixins/polymorphic/billing-item';
import Model, { attr, belongsTo } from '@ember-data/model';
import classic from 'ember-classic-decorator';
import generateUUID from 'ember-simplepractice/utils/generate-uuid';

const Validations = buildValidations({
  amount: [
    validator('number', {
      lte: reads('model.maximumAmount'),
      message: computed('model._invoice', function () {
        if (this.get('model._invoice')) {
          return 'Amount cannot be greater than invoice amount';
        } else {
          return 'Amount cannot be greater than total account balance';
        }
      }),
    }),
    validator('number', {
      gt: 0.5,
      allowNone: false,
      message: 'Amount must be greater than $0.50',
    }),
  ],
});

@classic
export default class PaymentModel extends Model.extend(BillingItemMixin, Validations) {
  @service stripe;

  @belongsTo({ async: false }) invoice;
  @belongsTo({ async: false }) card;
  @attr() displayName;
  @attr('moment') paidAt;
  @attr() amount;
  @attr() maximumAmount;

  // Virtual
  @attr() idempotencyKey;
  @attr() customCardToken;
  @attr() radarSession;
  @reads('paidAt') activityDate;
  @reads('amount') activityPayments;
  activityCharges = null;

  // eslint-disable-next-line ember/no-observers
  @observes('card', 'customCardToken')
  cardChanged() {
    if (this.isDeleted) {
      return;
    }
    this.set('idempotencyKey', generateUUID());
  }

  createRadarSession() {
    return new Promise((resolve, _) => {
      if (isEmberTesting()) {
        return resolve(this);
      }

      // eslint-disable-next-line promise/prefer-await-to-then
      return this.stripe.createRadarSession().then(result => {
        if (result.error) {
          resolve(this);
          return;
        }

        this.set('radarSession', result.radarSession.id);
        resolve(this);
      });
    });
  }
}
