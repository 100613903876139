import { action } from '@ember/object';
import { decodeText } from '../utils/messaging/format-text';
import { filterSystemAndCurrentUser } from '../utils/messaging/user';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { t } from 'ember-intl';
import Component from '@glimmer/component';
import FormatMessagingTimestamp from '../helpers/format-messaging-timestamp';
import styles from './conversation-list-item.module.scss';

export default class ConversationListItem extends Component {
  @service messaging;

  get isUnread() {
    return this.messaging.currentProfile.unreadConversationIds.includes(this.args.conversation.id);
  }

  get userNames() {
    return this.filteredUsers.map(user => user.name).join(' & ');
  }

  get filteredUsers() {
    return filterSystemAndCurrentUser(this.args.conversation?.users, this.messaging.currentProfile);
  }

  get hasMultipleUsers() {
    return this.filteredUsers.length > 1;
  }

  @action
  openConversation() {
    this.messaging.openConversation(this.args.conversation.id);
  }

  <template>
    <li
      class="{{styles.component}} conversation-list-item"
      data-test-conversation={{@conversation.id}}
      {{on "click" this.openConversation}}
      role="button"
    >
      <div class="avatar-container {{if this.hasMultipleUsers 'multiple-avatars'}}">
        {{#each this.filteredUsers as |user|}}
          <span class="initials" data-test-initials>
            {{user.initials}}
          </span>
        {{/each}}
      </div>

      <div class="item-details">
        <div class="top-line">
          {{#if this.isUnread}}
            <span
              class="unread-dot"
              aria-label={{t "unread_message_indicator"}}
              data-test-unread-indicator
            ></span>
          {{/if}}
          <span class="user-names" data-test-user-names>{{this.userNames}}</span>
          <span class="timestamp" data-test-timestamp>
            {{FormatMessagingTimestamp @conversation.updatedAt}}
          </span>
        </div>
        <p class="last-message" data-test-last-message>
          {{decodeText @conversation.lastMessage.text}}
        </p>
      </div>
    </li>
  </template>
}
