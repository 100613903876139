import { tracked } from '@glimmer/tracking';
import Service from '@ember/service';

export default class MessagingNavigationService extends Service {
  @tracked isConversationPageShown = false;
  @tracked selectedConversationId;
  @tracked conversationUsers;

  openConversationPage(id, users) {
    this.selectedConversationId = id;
    this.conversationUsers = users;
    this.isConversationPageShown = true;
  }

  closeConversationPage() {
    this.isConversationPageShown = false;
    this.selectedConversationId = null;
    this.conversationUsers = null;
  }
}
