import { service } from '@ember/service';
import Component from '@glimmer/component';
import ConversationListPage from './conversation-list-page.gjs';
import ConversationPage from './conversation-page.gjs';
import styles from './ember-messaging-client.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

export default class EmberMessagingClient extends Component {
  @service messagingNavigation;
  @service messaging;

  <template>
    <div class="{{styles.component}} messaging-client" data-test-ember-messaging-client>
      {{#if this.messaging.loadApp.isRunning}}
        <div class="spinner" data-test-loading-spinner>
          {{svgJar "messaging-spinner"}}
        </div>
      {{else if this.messagingNavigation.isConversationPageShown}}
        <ConversationPage />
      {{else}}
        <ConversationListPage />
      {{/if}}
    </div>
  </template>
}
