/* import __COLOCATED_TEMPLATE__ from './details.hbs'; */
import { CLIENT_PORTAL, PROFESSIONAL_WEBSITE } from 'client-portal/services/session';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
export default class PracticeWebsiteHeaderDetails extends Component {
  @service websiteWidget;
  @service currentPractice;
  @service session;

  @reads('openContactWidgetTask.isRunning') isLoading;
  @reads('currentPractice.featurePracticeContactForm') featurePracticeContactForm;

  init() {
    super.init(...arguments);

    if (this.featurePracticeContactForm && this.model.practiceContactForm) {
      this.session.channel = PROFESSIONAL_WEBSITE;
    } else if (!this.session.channel) {
      this.session.channel = CLIENT_PORTAL;
    }
  }

  @action
  onContactClick() {
    this.featurePracticeContactForm
      ? this.websiteWidget.openContactWidgetTask.perform()
      : this.toggleContactModal();
  }
}
