export const shouldShowBanner = isFastboot => {
  if (!isFastboot) {
    return !document.cookie.includes('datagrail_consent_preferences');
  }
  return true;
};

export const analyticsEnabled = async preferences => {
  return preferences.consentPreferences.cookieOptions.find(
    option => option.gtm_key === 'dg-category-analytics'
  ).isEnabled;
};
