import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import InfinityModel from 'ember-infinity/lib/infinity-model';

export const CONVERSATIONS_PER_PAGE = 30;
export const MESSAGES_PER_PAGE = isEmberTesting() ? 10 : 30;

export function pageNumberInfinityModel(perPage = CONVERSATIONS_PER_PAGE) {
  return class ExtendedInfinityModel extends InfinityModel {
    afterInfinityModel(model) {
      this.set('canLoadMore', model.length >= perPage);
    }
  };
}

export function pageNumberInfinityOptions(perPage = CONVERSATIONS_PER_PAGE) {
  return {
    startingPage: 1,
    perPage,
    perPageParam: 'page[size]',
    pageParam: 'page[number]',
  };
}
