import { service } from '@ember/service';
import JSONAPIAdapter from '@ember-data/adapter/json-api';

export const MESSAGING_API_VERSION = '2021-06-10';
export const MESSAGING_APPLICATION_PLATFORM = 'web';

export default class MessagingBase extends JSONAPIAdapter {
  @service messaging;

  get headers() {
    return {
      'Api-Version': MESSAGING_API_VERSION,
      'X-Access-Token': this.messaging.token,
      'X-Application-Platform': MESSAGING_APPLICATION_PLATFORM,
    };
  }

  get host() {
    return this.messaging.messagingApiUrl || '';
  }

  pathForType(type) {
    return super.pathForType(type).replace('messaging-', '');
  }
}
