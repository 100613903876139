import { StringValidation } from '../../../../utils/component-validations';
import Container from '../../core/container.gjs';
import Super from '../../core/base.gjs';

export default class SpdsAlertBannerMessageBody extends Super {
  arguments = [new StringValidation({ required: ['body'] })];
  baseClass = 'spds-alert-banner-message-body';
  componentName = 'Spds::AlertBanner::Message::Body';

  <template>
    <Container
      @content={{@body}}
      class={{this.classes}}
      data-test-spds-alert-banner-message-body
      {{this.validateArguments}}
      ...attributes
    />
  </template>
}
