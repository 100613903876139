import { service } from '@ember/service';
import ApplicationAdapter from './application';
import getSegmentHeaders from 'client-portal/utils/segment-header';

export default class SignInToken extends ApplicationAdapter {
  @service analytics;

  get headers() {
    return {
      ...super.headers,
      ...getSegmentHeaders(this.analytics),
    };
  }
}
