/* import __COLOCATED_TEMPLATE__ from './payment-amount-list.hbs'; */
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class SiteBillingPaymentModalPaymentAmountList extends Component {
  @service billingModals;
  @service stripeExpressCheckoutElementService;

  @tracked selectedPaymentAmountType = null;
  @tracked validateOtherAmount = false;

  get showOtherAmountInput() {
    return this.selectedPaymentAmountType === 'other';
  }

  @action
  switchToTotalAmount() {
    this.selectedPaymentAmountType = 'total';
    this.args.setPaymentDetails({
      amount: this.billingModals.balanceDue,
      maximumAmount: this.billingModals.balanceDue,
      invoice: null,
    });
  }
  @action
  switchToInvoiceAmount() {
    this.selectedPaymentAmountType = 'invoice';
    this.args.setPaymentDetails({
      amount: this.args.model._invoice.remainingAmount,
      maximumAmount: this.args.model._invoice.remainingAmount,
      invoice: this.args.model._invoice,
    });
  }

  @action
  switchToOtherAmount() {
    this.selectedPaymentAmountType = 'other';
    this.args.setPaymentDetails({
      amount: null,
      maximumAmount: this.maximumAmount(),
      invoice: null,
    });
    this.validateOtherAmount = false;
  }

  @action
  updateOtherAmount(amount) {
    this.args.setPaymentDetails({
      amount,
    });
    this.validateOtherAmount = true;
  }

  maximumAmount() {
    if (this.args.model._invoice) {
      return this.args.model._invoice.remainingAmount;
    } else {
      return this.billingModals.balanceDue;
    }
  }

  get totalBalanceIsNotPositive() {
    return this.billingModals.balanceDue <= 0;
  }

  get totalBalanceCreditSuffix() {
    return this.billingModals.balanceDue < 0 ? 'Credit' : '';
  }

  get totalBalanceIsLessThanInvoice() {
    return this.billingModals.balanceDue < this.args.model._invoice.remainingAmount;
  }

  get isExpressCheckoutElementBusy() {
    return (
      this.stripeExpressCheckoutElementService.isOpen ||
      this.stripeExpressCheckoutElementService.isPersisting
    );
  }

  get displayTotalBalanceHelp() {
    return this.args.model._invoice && this.totalBalanceIsLessThanInvoice;
  }
}
