import { TrackedArray, TrackedMap } from 'tracked-built-ins';
import { action } from '@ember/object';
import { filterSystemAndCurrentUser } from '../utils/messaging/user';
import { getMessageDateLabel } from '../utils/messaging/format-date';
import { modifier } from 'ember-modifier';
import { on } from '@ember/modifier';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { waitForNext } from 'ember-simplepractice/utils/waiters';
import Component from '@glimmer/component';
import ConversationMessage from './conversation-message.gjs';
import MessagingPage from './messaging-page.gjs';
import SharedInfinityLoader from 'ember-simplepractice/components/shared/infinity-loader';
import styles from './conversation-page.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

export default class ConversationPage extends Component {
  @service messagingNavigation;
  @service messaging;
  @service infinity;
  @service alertManager;

  @tracked messagesInfinityModel;
  chatElement;
  groupedMessagesMap = new TrackedMap();

  onInsert = modifier(async e => {
    this.chatElement = e;
    await waitForNext();
    try {
      this.messagesInfinityModel = await this.messaging.fetchMessages.perform();
      this.addMessagesToMap(this.messagesInfinityModel);
    } catch (err) {
      this.close();
      this.alertManager.simpleToast('Something went wrong on our end. Please try again', 'error');
    }
  });

  get userNames() {
    return this.filteredUsers.map(user => user.name).join(' & ');
  }

  get reversedMessagesDates() {
    return Array.from(this.groupedMessagesMap.entries())
      .map(([date]) => date)
      .reverse();
  }

  @action
  getMessagesForDate(date) {
    return this.groupedMessagesMap.get(date);
  }

  get filteredUsers() {
    return filterSystemAndCurrentUser(
      this.messagingNavigation.conversationUsers,
      this.messaging.currentProfile
    );
  }

  @action
  async loadMoreMessages(model) {
    if (!model.canLoadMore) return;

    try {
      let previousScrollTop = this.chatElement.scrollTop;
      let data = await this.infinity.loadNextPage(model);
      let newMessages = this.messagesInfinityModel.slice((data.currentPage - 1) * data.perPage);

      this.addMessagesToMap(newMessages);
      await waitForNext();
      this.chatElement.scrollTop = previousScrollTop;
    } catch (err) {
      this.alertManager.simpleToast('Something went wrong on our end. Please try again', 'error');
    }
  }

  addMessagesToMap(messages) {
    messages.forEach(message => {
      let dateKey = new Date(message.createdAt).toDateString();

      if (!this.groupedMessagesMap.has(dateKey)) {
        this.groupedMessagesMap.set(dateKey, new TrackedArray());
      }

      this.groupedMessagesMap.get(dateKey).unshift(message);
    });
  }

  @action
  close() {
    this.messagingNavigation.closeConversationPage();
  }

  <template>
    <MessagingPage
      class="{{styles.component}} conversation-page"
      @isShown={{this.messagingNavigation.isConversationPageShown}}
    >
      <:header>
        <button
          class="button transparent back-button"
          type="button"
          data-test-conversation-page-back-button
          {{on "click" this.close}}
        >
          {{svgJar "messaging-chevron-left"}}
        </button>
        <span>{{this.userNames}}</span>
      </:header>
      <:default>
        <div class="content" data-test-conversation-page-content {{this.onInsert}}>
          {{#if this.messaging.fetchMessages.isRunning}}
            <div class="spinner" data-test-loading-spinner>
              {{svgJar "messaging-spinner"}}
            </div>
          {{else}}
            <div class="messages">
              {{#if this.messagesInfinityModel}}
                <SharedInfinityLoader
                  @model={{this.messagesInfinityModel}}
                  @infinityLoad={{this.loadMoreMessages}}
                  @triggerOffset={{0}}
                />
              {{/if}}
              {{#each this.reversedMessagesDates as |date|}}
                <div class="date-label">{{getMessageDateLabel date}}</div>
                {{#each (this.getMessagesForDate date) as |message|}}
                  <ConversationMessage @message={{message}} />
                {{/each}}
              {{/each}}
            </div>
          {{/if}}
        </div>
      </:default>
      <:footer>
        <div class="input-area">
          <textarea class="input" placeholder="Send a message" aria-label="Message" />
          <button disabled class="button primary send-button" type="button">Send</button>
        </div>
        <span class="hint"><strong>Shift + Return</strong> to add a new line</span>
      </:footer>
    </MessagingPage>
  </template>
}
