export function indexInRange(length, index, start = 0) {
  return index >= start && index < length;
}

export function verifyHtmlTag(fallback, tag = fallback) {
  let isValid = document.createElement(tag).toString() !== '[object HTMLUnknownElement]';

  return isValid ? tag : fallback;
}

export default { indexInRange, verifyHtmlTag };
