import Component from '@glimmer/component';
import styles from './messaging-page.module.scss';

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class MessagingPage extends Component {
  <template>
    {{#if @isShown}}
      <div class="{{styles.component}} messaging-page" ...attributes>
        {{#if (has-block "header")}}
          <header class="messaging-page-header">
            {{yield to="header"}}
          </header>
        {{/if}}
        {{yield}}
        {{#if (has-block "footer")}}
          <footer class="messaging-page-footer">
            {{yield to="footer"}}
          </footer>
        {{/if}}
      </div>
    {{/if}}
  </template>
}
