/* global FastBoot */
import { helper } from '@ember/component/helper';
import ENV from 'client-portal/config/environment';

const MARKETING_URL = 'https://www.simplepractice.com';
const SUPPORT_URL = 'https://support.simplepractice.com/hc/en-us';
let BASE_URL = 'WEBAPP_BASE_PLACEHOLDER';

if (
  typeof FastBoot === 'undefined' &&
  ['development', 'test'].some(env => ENV.railsEnv === env || ENV.environment === env)
) {
  let urlFromLocation = `http://${window.location.host.split('.').slice(1).join('.')}`;
  BASE_URL = window.location.port === '' ? ENV.oauthBaseUrl || urlFromLocation : urlFromLocation;
}

export function routeFor([name, path]) {
  /* eslint-disable camelcase */
  let staticRoutes = {
    support_article_url: `${SUPPORT_URL}/articles/${path}`,
    marketing_url: `${MARKETING_URL}/${path}`,
    oauth_url: `${BASE_URL}/client-portal-api/client_accesses/auth/${path}`,
  };

  if (staticRoutes[name]) {
    if (name === 'support_article_url') {
      if (
        ['development', 'test'].some(env => ENV.railsEnv === env || ENV.environment === 'test') &&
        typeof path !== 'string'
      ) {
        throw new Error(`route-for expected a string, but received a ${typeof path}`);
      }
    }
    return staticRoutes[name];
  } else {
    throw new Error(`route-for could not find static route ${name}`);
  }
}

export default helper(routeFor);
