import { pluralize } from 'ember-inflector';
import { service } from '@ember/service';
import ApplicationAdapter from './application';
import classic from 'ember-classic-decorator';
import getSegmentHeaders from 'client-portal/utils/segment-header';

@classic
export default class Session extends ApplicationAdapter {
  @service analytics;

  get headers() {
    return {
      ...super.headers,
      ...getSegmentHeaders(this.analytics),
    };
  }

  urlForCreateRecord(modelName, snapshot) {
    return `${this.buildURL()}/${pluralize(modelName)}/${snapshot.attr('type')}`;
  }
}
