/* import __COLOCATED_TEMPLATE__ from './cta-buttons.hbs'; */
import { and, reads } from '@ember/object/computed';
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './cta-buttons.module.scss';

@classic
@classNames(styles.component)
export default class PracticeWebsiteCtaButtons extends Component {
  @service request;
  @service currentPractice;
  @service analytics;
  @service websiteWidget;

  @and('model.showRequestAppointmentLink', 'currentPractice.requestAsNewClient') showRequestBtn;
  @reads('openSchedulingWidgetTask.isRunning') isLoading;

  @computed('model.showContactCta', 'showRequestBtn')
  get firstBtnClassName() {
    return this.showRequestBtn ? 'pw-secondary' : 'pw-primary';
  }

  @(task(function* () {
    this.mixpanelTrack?.('Open appt request');
    this.analytics.trackAnonymously('Appointment Request Opened', {
      object: 'Appointment Request',
      action: 'Opened',
      'source_application': 'Professional Website',
    });
    let previewModel = this.preview ? this.model : undefined;
    yield this.websiteWidget.revealTask.perform(previewModel);
    yield timeout(400); // widget fade in animation delay
  }).drop())
  openSchedulingWidgetTask;
}
