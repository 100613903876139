import Model, { attr, belongsTo } from '@ember-data/model';

export default class MessagingMessageModel extends Model {
  @belongsTo('messaging-conversation', { async: false }) conversation;
  @belongsTo('messaging-user', { async: false, inverse: null }) user;

  @attr('boolean') isOwn;
  @attr('boolean') isSystem;

  // Renamed "content" in serializer as it collides with reserved attribute name.
  @attr('string') text;

  @attr('string') localId;

  @attr('date') createdAt;
  @attr('date') deletedAt;

  @attr() readMemberships;
}
