import { computed } from '@ember/object';
import { equal, or } from '@ember/object/computed';
import Model, { attr } from '@ember-data/model';
import classic from 'ember-classic-decorator';

export const TYPES = [
  'document-request-note',
  'document-request-consent-document',
  'document-request-contact-info',
  'document-request-credit-card-info',
  'document-request-questionnaire',
  'document-request-stored-document',
  'document-request-good-faith-estimate',
];

export const MIME_TYPE_ICONS = {
  'text/csv': 'csv',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'doc',
  'image/jpeg': 'jpg',
  'application/pdf': 'pdf',
  'image/png': 'png',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
};

@classic
export default class DocumentRequestModel extends Model {
  static fieldsFor(_context) {
    let fields = [];
    this.fields.forEach((_, v) => fields.pushObject(v));
    fields.removeObject('meta');
    return fields;
  }

  @attr() documentTitle;
  @attr() status;
  @attr('moment') createdAt;
  @attr('moment') updatedAt;
  @attr('boolean') hasDocumentPdf;

  // Passed through from serializer
  @attr() meta;

  @equal('status', 'completed') isComplete;
  @equal('status', 'locked') isLocked;
  @equal('status', 'sent') isSent;
  @equal('status', 'viewed') isViewed;
  @equal('status', 'reviewing') isReviewing;
  @or('isReviewing', 'isComplete') isReview;
  @or('isComplete', 'isLocked') showAsCompleted;

  @computed('constructor.modelName')
  get requestType() {
    return this.constructor.modelName.replace('document-request-', '');
  }
}
