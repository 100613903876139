import Model, { attr, hasMany } from '@ember-data/model';

export const EXTERNAL_SCOPE_TYPES = {
  client: 'Client',
  system: 'System',
  user: 'User',
};

export default class MessagingUserModel extends Model {
  @hasMany('messaging-conversation', { async: true, inverse: null }) conversations;
  @hasMany('messaging-message', { async: true, inverse: null }) messages;

  @attr('boolean') isAway;

  @attr('string') awayMessageText;
  @attr('string') externalId;
  @attr('string') externalScope;
  @attr('string') name;
  @attr('string') organizationId;

  get initials() {
    const splitName = this.name.trim().split(' ');
    return splitName.reduce((acc, value, index) => {
      if (index === 0 || index === splitName.length - 1) {
        acc = `${acc}${value.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
  }

  get isSystemUser() {
    return (
      this.organizationId === '0' &&
      this.externalId === '0' &&
      this.externalScope === 'System' &&
      this.name === 'System'
    );
  }
}
