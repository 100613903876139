import MessagingBase from './messaging-base';

export default class MessagingMessage extends MessagingBase {
  buildURL(modelName, id, snapshot, requestType, query) {
    if (query?.conversationId) {
      let conversationId = query.conversationId;
      delete query.conversationId;
      return `${this.host}/conversations/${conversationId}/messages`;
    }
    return super.buildURL(modelName, id, snapshot, requestType, query);
  }
}
