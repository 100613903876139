/* import __COLOCATED_TEMPLATE__ from './documents-page.hbs'; */
import { classNames } from '@ember-decorators/component';
import { computed } from '@ember/object';
import { filterBy, reads } from '@ember/object/computed';
import { htmlSafe } from '@ember/template';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './documents-page.module.scss';

@classic
@classNames(styles.component)
export default class SiteDocumentsPage extends Component {
  @reads('model.meta.hasDocumentsIntro') hasDocumentsIntro;

  @computed('model.meta.welcomeText')
  get welcomeText() {
    return htmlSafe(this.model.meta.welcomeText);
  }

  @filterBy('model', 'showAsCompleted', false) incompleteRecords;

  @filterBy('model', 'showAsCompleted', true) completedRecords;
}
