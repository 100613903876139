import { assert, runInDebug } from '@ember/debug';
import { isEmpty } from '@ember/utils';

const ACTION = 'action';
const ARRAY = 'array';
const BOOLEAN = 'boolean';
const CONFIG_OPTION = 'configOption';
const NUMBER = 'number';
const OBJECT = 'object';
const OPTIONAL = 'optional';
const STRING = 'string';
const nonexistentOptions = [undefined, null];
const VALIDATABLE_TYPES = [ACTION, ARRAY, BOOLEAN, CONFIG_OPTION, NUMBER, OBJECT, STRING];

const configOptionAssertions = {
  forbidden: forbiddenAssertion,
  optional: optionalConfigOption,
  required: requiredConfigOption,
};
const defaultAssertions = {
  forbidden: forbiddenAssertion,
  optional: optionalAssertion,
  required: requiredAssertion,
};

/**
 * Base class for creating validation objects.
 * This class is used to define the type of validation, the assertions to apply,
 * and the list of validations to perform.
 * @argument {string} type the expected data type to validate against
 * @argument {object} assertions an object with attributes reflecting the available assertions to use for validation
 * @argument {object} validations an object with attributes reflecting the list of arguments to validate
 * This class is primarily used as a base for more specific validation classes
 * (e.g., `StringValidation`, `NumberValidation`).
 */
class ValidationObject {
  validations = {};

  constructor(type, assertions, validations) {
    runInDebug(() => {
      assert(
        `provided type ${type} for ${this.constructor.name} is invalid`,
        VALIDATABLE_TYPES.includes(type)
      );

      this.assertions = assertions ?? defaultAssertions;
      this.type = type;

      Object.keys(this.assertions).forEach(key => (this.validations[key] = validations[key]));
    });
  }
}

/**
 * Checks if the provided argument is either `undefined` or `null`.
 * Used to determine if a value is "nonexistent."
 *
 * @param {*} argument - The value to check.
 * @returns {boolean} - Returns `true` if the argument is `undefined` or `null`, otherwise `false`.
 */
function doesNotExist(argument) {
  return nonexistentOptions.includes(argument);
}

/**
 * Validates that the provided argument has no value (is `undefined` or `null`).
 * Throws an error if the argument has a value.
 *
 * @param {object} param0 - An object containing:
 *   - `argument`: The value to validate.
 *   - `message`: The error message to display if validation fails.
 */
function forbiddenAssertion({ argument, message }) {
  assert(message, nonexistentOptions.includes(argument));
}

/**
 * hasCorrectType checks that the provided value has the expected data type
 * @param {*} argument the value to check
 * @param {string} type the data type to validate the argument against
 * @returns {boolean} returns `true` if the argument passes the validation, otherwise `false`
 */
function hasCorrectType(argument, type) {
  return type === ARRAY ? Array.isArray(argument) : typeof argument === type;
}

/**
 * optionalAssertion validates that the argument either has the expected data type
 * or has not been provided
 * if the argument fails this assertion, the provided message is thrown as an Error
 * @param {object} param0 with attributes message, type, argument
 */
function optionalAssertion({ argument, message, type }) {
  assert(message, typeof hasCorrectType(argument, type) || doesNotExist(argument));
}

/**
 * optionalConfigOption validates that the provided configuration option is either
 * one of the expected values or has not been provided
 * if the config option fails this assertion, the provided message is thrown as an Error
 * @param {object} param0 with attributes argument, options, name, component
 */
function optionalConfigOption({ argument, component, name, options }) {
  assert(
    `@${name} argument of ${component} must be one of ${options.join(', ')} or empty`,
    options.includes(argument) || doesNotExist(argument)
  );
}

/**
 * parseArgumentName allows for retrieval of nested values inside of an object for validation
 * if the name is separate by periods, ex: `object.attribute.deeperAttribute`
 * @param {object} args a nested object traversable by the provided name
 * @param {string} name the name to be parsed
 * @returns {*} the value at the terminal access point of the provided name
 */
function parseArgumentName(args, name) {
  let argument = args;
  // handling for nested attributes
  name.split('.').forEach(segment => (argument = argument[segment]));

  return argument;
}

/**
 * requiredAssertion validates that the provided argument exists and has the expected data type
 * if the argument fails this assertion, the provided message is thrown as an Error
 * @param {object} param0 with attributes argument, message, type
 */
function requiredAssertion({ argument, message, type }) {
  assert(message, typeof hasCorrectType(argument, type) && !isEmpty(argument));
}

/**
 * requiredConfigOption validates that the provided argument is one of the values in options
 * if the argument fails this assertion, the provided message is thrown as an Error
 * @param {object} param0 with attributes argument, component, name, options
 */
function requiredConfigOption({ argument, component, name, options }) {
  assert(
    `@${name} argument of ${component} must be one of ${options.join(', ')}`,
    options.includes(argument) && !isEmpty(argument)
  );
}

/**
 * Class for validating Action values
 * extends ValidationObject class
 */
export class ActionValidation extends ValidationObject {
  constructor() {
    super(ACTION, undefined, ...arguments);
  }
}

/**
 * Class for validating Array values
 * extends ValidationObject class
 */
export class ArrayValidation extends ValidationObject {
  constructor() {
    super(ARRAY, undefined, ...arguments);
  }
}

/**
 * Class for validating Boolean values
 * extends ValidationObject class
 */
export class BooleanValidation extends ValidationObject {
  constructor() {
    super(BOOLEAN, undefined, ...arguments);
  }
}

/**
 * Class for validating ConfigOption values
 * extends ValidationObject class
 */
export class ConfigOptionValidation extends ValidationObject {
  constructor() {
    super(CONFIG_OPTION, configOptionAssertions, ...arguments);
  }
}

/**
 * Class for validating Number values
 * extends ValidationObject class
 */
export class NumberValidation extends ValidationObject {
  constructor() {
    super(NUMBER, undefined, ...arguments);
  }
}

/**
 * Class for validating Object values
 * extends ValidationObject class
 */
export class ObjectValidation extends ValidationObject {
  constructor() {
    super(OBJECT, undefined, ...arguments);
  }
}

/**
 * Class for validating String values
 * extends ValidationObject class
 */
export class StringValidation extends ValidationObject {
  constructor() {
    super(STRING, undefined, ...arguments);
  }
}

/**
 * selectConfigOption verifies that the selected value is present in the set of options
 * provided. if it is not, then a fallback value is returned
 * @param {Array<string>} options
 * @param {string} selected
 * @param {string} fallback
 * @returns {string}
 */
export function selectConfigOption(options, selected, fallback) {
  return options.find(option => option === selected) || fallback;
}

/**
 * getValidationData constructs the validation data object for a given argument
 * @param {Array<object>} list the validation list
 * @param {string} listName the name of the validation list
 * @param {object} args the arguments object
 * @param {string} component the name of the component
 * @param {object|string} item the item to validate
 * @returns {object} the constructed validation data object
 */
function getValidationData(list, listName, args, component, item) {
  let name = typeof item === 'string' ? item : item.name;
  let validationData = { argument: parseArgumentName(args, name), component };
  let additionalData;

  if (list.type === 'configOption') {
    additionalData = { name, options: item.options };
  } else if (listName === 'forbidden') {
    additionalData = {
      message: `@${name} argument of ${component} is not allowed because ${item.reason}`,
    };
  } else {
    let { type } = list;
    let compareType = type === 'action' ? 'function' : type;
    let messageBase = `argument of ${component} must have a type of "${type}"${listName === OPTIONAL ? ' or be empty' : ''}`;
    additionalData = {
      message: `@${name} ${messageBase}. Received ${args[name]}`,
      type: compareType,
    };
  }

  return Object.assign(validationData, additionalData);
}

/**
 * validateArgumentList traverses the supplied argumentList and runs all applicable validations against
 * the args object, ensuring that the values in args are valid
 * @param {object} param0 with attributes args {object}, argumentList {array}, and component {string}
 */
export function validateArgumentList({ args = {}, argumentList, component }) {
  // can't properly compose validation messages without component
  // this should be a _very_ rare case, as `validateArguments` falls back to `constructor.name`
  // when no componentName is provided
  assert(
    'component name must be present to properly compose error messages while validating components',
    typeof component === 'string' && component.length > 0
  );
  // this is to ensure the `arguments` array is properly added to the validating component
  assert(
    `argumentList must be an array to properly validate arguments for ${component}`,
    Array.isArray(argumentList)
  );

  argumentList.forEach(list => {
    // iterate over the list of validations and use the list name (forbidden, optional, required)
    // to determine the validation to use for items in the list
    assert(`argumentList of ${component} should have validations defined`, !!list.validations);
    assert(`argumentList of ${component} should have assertions defined`, !!list.assertions);

    Object.keys(list.validations).forEach(listName => {
      let validationsList = list.validations[listName];

      if (doesNotExist(validationsList)) return;

      validationsList.forEach(item => {
        list.assertions[listName]?.(getValidationData(list, listName, args, component, item));
      });
    });
  });
}

export default {
  ActionValidation,
  BooleanValidation,
  ConfigOptionValidation,
  NumberValidation,
  ObjectValidation,
  StringValidation,
  selectConfigOption,
  validateArgumentList,
};
