import { classNames } from '@ember-decorators/component';
import Component from '@ember/component';
import chromeSrc from '../../../assets/images/browsers/chrome.png';
import classic from 'ember-classic-decorator';
import edgeSrc from '../../../assets/images/browsers/edge.png';
import firefoxSrc from '../../../assets/images/browsers/firefox.png';
import safariSrc from '../../../assets/images/browsers/safari.png';
import styles from './browsers.module.scss';

const browserVersions = [
  {
    short: 'c',
    name: 'Chrome',
    url: 'https://www.google.com/chrome/',
    imageSrc: chromeSrc,
  },
  {
    short: 'e',
    name: 'Edge',
    url: 'https://www.microsoft.com/en-us/edge',
    imageSrc: edgeSrc,
  },
  {
    short: 's',
    name: 'Safari',
    url: 'https://support.apple.com/en-us/HT204416',
    imageSrc: safariSrc,
  },
  {
    short: 'f',
    name: 'Firefox',
    url: 'https://www.mozilla.org/en-US/firefox/new/',
    imageSrc: firefoxSrc,
  },
];

@classic
@classNames(styles.component)
export default class SharedUnsupportedBrowserModalBrowsers extends Component {
  browserVersions = browserVersions;
}
