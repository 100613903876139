import {
  ActionValidation,
  StringValidation,
  validateArgumentList,
} from '../../../utils/component-validations';
import { dasherize } from '@ember/string';
import { guidFor } from '@ember/object/internals';
import { isEmpty } from '@ember/utils';
import { modifier } from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';
import { runInDebug } from '@ember/debug';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

const STYLE_ATTRIBUTES = ['baseStyles', 'styles'];

/**
 * Base class for SPDS components
 *
 * Arguments
 * @argument {string} id optional identifier; overrides automated ID generation
 * @argument {function} registerComponent allows registration of component with parent
 * @argument {function} unregisterComponent remove registration of component with parent
 *
 * @property {array} arguments an array of validation objects that validate the arguments for the inheriting component
 * @property {string} baseClass the base CSS class for the component; fallback is `spds-component`
 * @property {array} classesToMapToArgs an array of strings that list argument names which should be conditionally converted to CSS classes based on their value
 * @property {array} classesToMapToAttrs an array of strings that list attribute names which should be conditionally converted to CSS classes based on their value
 * @property {string} componentName an optional name for validation errors to reference for the component; defaults to this.constructor.name; provided by inheriting components
 */
export default class SpdsCoreBase extends Component {
  #setupRun = false;

  // fallbacks in case attrs not provided in inheriting component
  arguments = [];
  baseClass = 'spds-component';
  classesToMapToArgs = [];
  classesToMapToAttrs = [];

  @tracked baseElement;
  @tracked uniqueIdentifier;

  /**
   * allows for dynamically updating classes on component
   * based on argument or attribute values
   * inheriting components must define their own
   * `classesToMapToArgs` and `classesToMapToAttrs` arrays
   * @return {string} the processed CSS classes to be applied to the DOM element
   */
  get classes() {
    let classes = [this.styling, this.baseClass, this.additionalClasses];

    [...(this.classesMappedToArgs || []), ...(this.classesMappedToAttrs || [])].forEach(obj => {
      if (typeof obj.arg === 'boolean' && obj.arg) {
        classes.push(obj.class);
      } else if (typeof obj.arg === 'string' && !isEmpty(obj.arg)) {
        classes.push(obj.arg);
      }
    });

    return classes.filter(Boolean).join(' ');
  }

  /**
   * map CSS classes to arguments passed into the component
   * @return {array} array of objects mapping CSS classes and matching values
   */
  get classesMappedToArgs() {
    return this.#mapClassesToValues(this.classesToMapToArgs, this.args);
  }

  /**
   * map CSS classes to attributes defined on the component
   * @return {array} array of objects mapping CSS classes and matching values
   */
  get classesMappedToAttrs() {
    return this.#mapClassesToValues(this.classesToMapToAttrs, this);
  }

  /**
   * generate ID for component based on unique identifier generated in constructor
   * @return {string} the composed id, or the @id argument value
   */
  get id() {
    let { id } = this.args;
    let { baseClass, uniqueIdentifier } = this;

    return id ?? `${baseClass}-${uniqueIdentifier}`;
  }

  /**
   * allows for assigning some styles to a base component as `baseStyles` attribute
   * and inheriting component can assign its own styles based on `styles` attribute
   * @return {string} the styles imported from SCSS modules, processed into strings
   */
  get styling() {
    return STYLE_ATTRIBUTES.map(style => this[style]?.component ?? '')
      .filter(Boolean)
      .join(' ');
  }

  constructor() {
    super(...arguments);

    // used to generate DOM ID for component
    // by default generates a string formatted like `ember123`
    // this reduces it to just the numeric portion of the generated ID
    this.uniqueIdentifier = guidFor(this).replace(/[^0-9]/g, '');

    // this powers the ability to register a subcomponent with its parent
    // parent must define all behavior around how to handle registration/unregistration
    if (this.args.registerComponent) {
      this.args.registerComponent?.(this);

      // automatically unregister the component from the parent on destruction
      registerDestructor(this, () => {
        this.args.unregisterComponent?.(this.id);
      });
    }
  }

  /**
   * Generates a unique ID for subcomponents by appending a suffix to the current component's ID.
   * This ensures that subcomponents have unique and predictable DOM IDs.
   *
   * @param {string} suffix - The suffix to append to the current component's ID.
   * @returns {string} - The composed subcomponent ID in the format: `<component-id>-<suffix>`.
   */
  composeSubcomponentId(suffix) {
    return [this.id, '-', suffix].join('');
  }

  /**
   * generate array of objects that determine if mapped CSS class should be
   * applied based on arg or attr value
   * @param {Array<string>} classes array fo strings representing CSS class names to map to value source
   * @param {object} valueSource the object which should be used to find values matching the CSS class array
   * @returns
   * {
   *   arg: string or boolean,
   *   class: string // dasherized version of string from classes array
   * }
   */
  #mapClassesToValues(classes, valueSource) {
    return classes
      .filter(c => valueSource[c])
      .map(c => ({ arg: valueSource[c], class: dasherize(c) }));
  }

  /**
   * modifier to register DOM elements for component
   * should be called on container for component to properly set `baseElement` and query DOM
   * set `elementsToRegister` to be an array on component that inherits from this class
   * each array item should be an object with `name` and `selector` attributes
   * element is registered to `this[name]` based on DOM query within component baseElement
   */
  #registerElements = element => {
    this.baseElement = element;

    this.elementsToRegister?.forEach(({ name, selector }) => {
      let el = element.querySelector(selector);

      if (el) this[name] = el;
    });
  };

  // setup validations for universal arguments
  #setupUniversalValidations() {
    runInDebug(() => {
      this.arguments = [
        new ActionValidation({ optional: ['registerComponent', 'unregisterComponent'] }),
        new StringValidation({ optional: ['id'] }),
        ...this.arguments,
      ];
    });
  }

  setupComponent = modifier(element => {
    if (this.#setupRun) return;

    this.#setupUniversalValidations();
    this.#registerElements(element);

    this.setup?.();

    this.#setupRun = true;
  });

  // validate provided arguments for component
  validateArguments = modifier(() => {
    runInDebug(() => {
      if (!this.arguments) return;

      let { args, componentName, constructor, arguments: argumentList } = this;

      validateArgumentList({ args, argumentList, component: componentName || constructor.name });
    });
  });

  /**
   * set this[name] to ref for child component
   * must be called using the `fn` helper and passing name
   * ex: {{fn this.registerComponent "labelComponent"}}
   */
  registerComponent = (name, component) => {
    this[name] = component;
  };

  /**
   * remove ref for child component
   * must be called using `fn` helper and passing name
   * ex: {{fn this.unregisterComponent "labelComponent"}}
   */
  unregisterComponent = name => {
    this[name] = null;
  };
}
