import Model, { attr, belongsTo, hasMany } from '@ember-data/model';

export default class MessagingConversationModel extends Model {
  @hasMany('messaging-user', { async: false, inverse: null }) users;
  @hasMany('messaging-message', { async: false }) messages;
  @belongsTo('messaging-message', { async: false, inverse: null }) lastMessage;

  @attr('boolean', { defaultValue: false }) disabled;
  @attr('boolean', { defaultValue: false }) inactive;
  @attr('boolean', { defaultValue: false }) isCouple;

  // Might be the id for a related couple record.
  @attr('string') linkExternalId;

  @attr('date') readAt;
  @attr('date') updatedAt;

  @attr() counterpartReadAt;
}
