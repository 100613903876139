import { BooleanValidation, StringValidation } from '../../../utils/component-validations';
import { reads } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';
import Container from './container.gjs';
import SpdsAutofocus from '../../../modifiers/spds-autofocus';
import Super from './base.gjs';
import styles from './button.module.scss';

const ELEMENT_TAG_DEFAULT = 'button';

export default class SpdsCoreButton extends Super {
  arguments = [
    new BooleanValidation({
      optional: [
        'absolute',
        'autofocus',
        'column',
        'disabled',
        'error',
        'fitContent',
        'fitContentHeight',
        'fitContentWidth',
        'fixed',
        'inline',
      ],
    }),
    new StringValidation({ optional: ['tag', 'text', 'type'] }),
  ];
  baseClass = 'spds-button';
  componentName = 'Spds::Core::Button';
  styles = styles;

  @tracked baseElement;

  @reads('args.tag', ELEMENT_TAG_DEFAULT) tag;
  @reads('args.type', ELEMENT_TAG_DEFAULT) type;

  // only set role when not a button tag
  get role() {
    return this.tag === ELEMENT_TAG_DEFAULT ? undefined : ELEMENT_TAG_DEFAULT;
  }

  <template>
    <Container
      @absolute={{@absolute}}
      @column={{@column}}
      @content={{@text}}
      @error={{@error}}
      @fitContent={{@fitContent}}
      @fitContentHeight={{@fitContentHeight}}
      @fitContentWidth={{@fitContentWidth}}
      @fixed={{@fixed}}
      @inline={{@inline}}
      @tag={{this.tag}}
      class={{this.classes}}
      data-test-spds-button={{this.tag}}
      disabled={{@disabled}}
      role={{this.role}}
      type={{this.type}}
      ...attributes
      {{SpdsAutofocus @autofocus}}
      {{this.setupComponent}}
      {{this.validateArguments}}
    >
      {{yield}}
    </Container>
  </template>
}
