import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { setControllerFullscreenStatus } from 'client-portal/utils/widget';
import Route from '@ember/routing/route';

export default class ContactWidgetRoute extends Route {
  @service analytics;
  @service store;
  @service currentPractice;
  @service router;
  @service session;
  @service message;
  @service fastboot;

  queryParams = {
    clinicianId: { refreshModel: true },
    appearance: { refreshModel: true },
  };

  @action
  reveal() {
    this.#trackOpened();
  }

  beforeModel() {
    super.beforeModel(...arguments);

    if (
      !this.currentPractice.get('featurePracticeContactForm') ||
      (!this.currentPractice.get('practiceContactForm') &&
        !this.currentPractice.get('monarchContactForm'))
    ) {
      this.router.transitionTo('home');
    }
  }

  async model({ clinicianId }, _transition) {
    let clinician = clinicianId ? await this.store.findRecord('clinician', clinicianId) : null;
    let client = this.store.createRecord('prospective-client', {
      emails: [this.store.createRecord('email', { prospectiveClient: true })],
      phones: [this.store.createRecord('phone', { prospectiveClient: true })],
      globalMonarchChannelName: this.session.channel,
      clinician,
    });
    return { client, clinician };
  }

  setupController(controller) {
    super.setupController(...arguments);
    setControllerFullscreenStatus(controller, false);
  }

  activate(_transition) {
    super.activate(_transition);
    this.message.registerTarget(this);
  }

  deactivate(_transition) {
    super.deactivate(_transition);
    this.message.unregisterTarget();
  }

  afterModel(_resolvedModel, _transition) {
    super.afterModel(_resolvedModel, _transition);
    if (!this.fastboot.isFastBoot && window.self === window.top) {
      this.#trackOpened();
    }
  }

  #trackOpened() {
    this.analytics.trackAnonymously('Contact Form Widget Opened', {
      Object: 'Contact Form Widget',
      Action: 'Opened',
      'site_name': this.session.siteName,
    });
  }
}
