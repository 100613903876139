import Service from '@ember/service';

const INCOMING_MESSAGE_SCOPE = 'client-portal-origin-to-iframe';

export default class MessageService extends Service {
  constructor() {
    super(...arguments);
    this._onMessage = this._onMessage.bind(this);
    if (typeof document !== 'undefined') {
      window.addEventListener('message', this._onMessage, false);
    }
  }

  willDestroy() {
    if (typeof document !== 'undefined') {
      window.removeEventListener('message', this._onMessage, false);
    }
    super.willDestroy(...arguments);
  }

  registerTarget(target) {
    this.target = target;
  }

  unregisterTarget() {
    this.target = null;
  }

  _onMessage(message) {
    if (message.data.scope !== INCOMING_MESSAGE_SCOPE) {
      return;
    }

    if (message.data.action && this.target && typeof this.target.send === 'function') {
      this.target.send(message.data.action, message.data);
    }
  }
}
