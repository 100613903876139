import { ActionValidation, StringValidation } from '../../../../utils/component-validations';
import { on } from '@ember/modifier';
import Button from '../../core/button.gjs';
import Container from '../../core/container.gjs';
import Super from '../../core/base.gjs';

export default class SpdsAlertBannerMessageCta extends Super {
  arguments = [new ActionValidation({ optional: ['onPrimaryBtnClick', 'onSecondaryBtnClick'] })];
  baseClass = 'spds-alert-banner-message-cta';
  componentName = 'Spds::AlertBanner::Message::Cta';

  constructor() {
    super(...arguments);

    let { onPrimaryBtnClick, onSecondaryBtnClick } = this.args;
    let optional = [];
    let required = [];

    [
      { check: onPrimaryBtnClick, name: 'primaryBtnText' },
      { check: onSecondaryBtnClick, name: 'secondaryBtnText' },
    ].forEach(({ check, name }) => {
      if (check) {
        required.push(name);
      } else {
        optional.push(name);
      }
    });

    this.arguments.push(new StringValidation({ optional, required }));
  }

  get primaryBtnId() {
    return this.composeSubcomponentId('primary-btn');
  }

  get secondaryBtnId() {
    return this.composeSubcomponentId('secondary-btn');
  }

  <template>
    <Container
      class={{this.classes}}
      data-test-spds-alert-banner-message-cta
      {{this.validateArguments}}
    >
      {{#if @onPrimaryBtnClick}}
        <Button
          class="button primary"
          id={{this.primaryBtnId}}
          type="button"
          {{on "click" @onPrimaryBtnClick}}
        >
          {{@primaryBtnText}}
        </Button>
      {{/if}}
      {{#if @onSecondaryBtnClick}}
        <Button
          class="button ghost"
          id={{this.secondaryBtnId}}
          type="button"
          {{on "click" @onSecondaryBtnClick}}
        >
          {{@secondaryBtnText}}
        </Button>
      {{/if}}
    </Container>
  </template>
}
