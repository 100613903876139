/* import __COLOCATED_TEMPLATE__ from './documents-upload.hbs'; */
import {
  DEFAULT_DOCUMENTS_MAX_FILE_SIZE,
  DEFAULT_MAX_FILE_SIZE_TEST,
} from 'client-portal/services/file-upload';
import { action } from '@ember/object';
import { bannerError } from 'client-portal/utils/error-handling';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { modifier } from 'ember-modifier';
import { namespace } from 'client-portal/adapters/application';
import { pluralize } from 'ember-inflector';
import { service } from '@ember/service';
import { success } from 'client-portal/utils/banners';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import styles from './documents-upload.module.scss';

export default class SiteDocumentsUpload extends Component {
  @service analytics;
  @service fileUpload;
  @service fileQueue;
  @service session;
  @service store;
  @tracked styles = styles;
  @tracked documentsQueue;

  queueName = 'documents';
  successCount = 0;
  maxFileSize = isEmberTesting() ? DEFAULT_MAX_FILE_SIZE_TEST : DEFAULT_DOCUMENTS_MAX_FILE_SIZE;

  constructor() {
    super(...arguments);

    this.documentsQueue = this.fileQueue.findOrCreate(this.queueName);
  }

  onSetup = modifier(
    async () => {
      // Escape autotracking frame and avoid backtracking re-render
      await Promise.resolve();
      this.queryTask.perform({ fetch: true });
    },
    { eager: false }
  );

  get pendingFiles() {
    if (!this.documentsQueue) {
      return [];
    }

    return this.documentsQueue.files
      .filter(x => x.state === 'queued' || x.state === 'uploading')
      .reverse();
  }

  @action
  setDocumentFile(file) {
    this.uploadFileTask.perform(file);
  }

  @task(function* (file) {
    try {
      let payload = yield this.fileUpload.validateAndUploadTask.perform(file, {
        route: `/${namespace}/documents`,
        headers: this.store.adapterFor('application').headers,
        maxFileSize: this.maxFileSize,
      });
      this.store.pushPayload(payload.body);
      yield this.queryTask.perform({ fetch: false });

      this.successCount += 1;
      if (this.pendingFiles.length === 0) {
        this.trackEvent(file, 'Uploaded');
        success({
          title: `${pluralize(this.successCount, 'document')} uploaded successfully`,
        });
        this.successCount = 0;
      }
    } catch (err) {
      this.documentsQueue.remove(file);
      bannerError(
        { errors: this.fileUpload.normalizeError(err) },
        { title: 'The file could not be uploaded' }
      );
    }
  })
  uploadFileTask;

  @(task(function* ({ fetch }) {
    if (fetch) {
      return yield this.store.query('document', { sort: '-createdAt' });
    }

    return this.store
      .peekAll('document')
      .toArray()
      .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
  }).keepLatest())
  queryTask;

  trackEvent(file, action) {
    let trackingProperties = {
      action,
      'attach_type': file.source === 'browse' ? 'File Dialog' : 'Dragged and Dropped',
      'client_id': this.session.currentClient.hashedId,
      'file_type': file.extension,
      object: 'Client File',
      referrer: 'client portal documents',
    };

    this.analytics.track(`Client File ${action}`, trackingProperties);
  }
}
