import { tracked } from '@glimmer/tracking';
import { waitForNext } from '../../utils/waiters';
import Component from '@glimmer/component';

export default class SharedFragmentArrayModelProvider extends Component {
  @tracked model;

  constructor() {
    super(...arguments);
    this.setup();
  }

  async setup() {
    await waitForNext();
    let model = this._createFragment();
    this.model = model;
  }

  willDestroy() {
    super.willDestroy(...arguments);

    if (this.model?.isNew && !this.model.store.isDestroying) {
      this.model.unloadRecord();
    }
  }

  _createFragment() {
    this.args.fragmentArray.createFragment(this.args.modelProperties || { name: '' });
    return this.args.fragmentArray.popObject();
  }

  <template>{{yield this.model}}</template>
}
