import { BooleanValidation, StringValidation } from '../../../utils/component-validations';
import { bool, or } from 'macro-decorators';
import { element } from 'ember-element-helper';
import { htmlSafe } from '@ember/template';
import { modifier } from 'ember-modifier';
import { verifyHtmlTag } from '../../../utils/spds-support';
import Super from './base.gjs';
import styles from './container.module.scss';

const ELEMENT_TAG_DEFAULT = 'div';

export default class SpdsCoreContainer extends Super {
  arguments = [
    new BooleanValidation({
      optional: [
        'absolute',
        'column',
        'discardClick',
        'error',
        'fitContent',
        'fitContentHeight',
        'fitContentWidth',
        'fixed',
        'inline',
        'inputContainer',
      ],
    }),
    new StringValidation({
      optional: ['content', 'tag'],
    }),
  ];
  baseClass = 'spds-container';
  classesToMapToArgs = ['absolute', 'column', 'error', 'fixed', 'inline', 'inputContainer'];
  classesToMapToAttrs = ['fitContentHeight', 'fitContentWidth'];
  styles = styles;

  @bool('args.fitContent') fitContent;
  @bool('args.fitContentHeight') fitContentH;
  @bool('args.fitContentWidth') fitContentW;
  @bool('args.discardClick') shouldDiscardClick;

  @or('fitContent', 'fitContentH') fitContentHeight;
  @or('fitContent', 'fitContentW') fitContentWidth;

  get content() {
    return htmlSafe(this.args.content);
  }

  get tag() {
    return verifyHtmlTag(ELEMENT_TAG_DEFAULT, this.args.tag);
  }

  setupDiscardClick = modifier(() => {
    if (this.clickHandlerSetup || !this.shouldDiscardClick || !this.baseElement) return;

    this.clickHandlerSetup = true;

    this.baseElement.addEventListener('click', this.ignoreClick);

    return () => {
      this.baseElement.removeEventListener('click', this.ignoreClick);
    };
  });

  ignoreClick = evt => {
    evt.stopPropagation();
  };

  <template>
    {{#let (element this.tag) as |Tag|}}
      <Tag
        class={{this.classes}}
        data-test-spds-container={{this.tag}}
        {{this.setupComponent}}
        {{this.setupDiscardClick}}
        {{this.validateArguments}}
        ...attributes
      >
        {{#if @content}}
          {{this.content}}
        {{else}}
          {{yield}}
        {{/if}}
      </Tag>
    {{/let}}
  </template>
}
