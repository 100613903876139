import {
  MESSAGES_PER_PAGE,
  pageNumberInfinityModel,
  pageNumberInfinityOptions,
} from '../utils/messaging/infinity-model';
import { all, dropTask, task, timeout } from 'ember-concurrency';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';

const TOKEN_REFRESH_INTERVAL_MINS = 4.5;

export default class MessagingService extends Service {
  @service infinity;
  @service messagingNavigation;
  @service('websocket/messagingWebsocket') messagingWebsocket;
  @service store;

  @tracked conversations;
  @tracked currentProfile;

  messagingApiUrl = '';
  token;

  get tokenRefreshIntervalMins() {
    return isEmberTesting() ? 0 : TOKEN_REFRESH_INTERVAL_MINS;
  }

  async start(messagingApiUrl = '') {
    this.messagingApiUrl = messagingApiUrl;
    await this.loadApp.perform();
  }

  stop() {
    this.pollMessagingToken.cancelAll();
    this.messagingWebsocket.closeSocket();
  }

  loadApp = task(async () => {
    await this.getMessagingToken.perform();
    await all([this.getCurrentProfile.perform(), this.getConversations.perform()]);
    this.pollMessagingToken.perform();
    this.messagingWebsocket.openSocket(this.messagingApiUrl, this.token);
  });

  pollMessagingToken = task(async () => {
    do {
      await timeout(this.tokenRefreshIntervalMins * 60 * 1000);
      await this.getMessagingToken.perform();
    } while (!isEmberTesting());
  });

  getMessagingToken = task(async () => {
    const response = await fetch('/messaging/tokens', {
      method: 'POST',
    });
    const json = await response.json();
    this.token = json.token;
  });

  getCurrentProfile = task(async () => {
    this.currentProfile = await this.store.queryRecord('messaging-profile', {
      include: 'system_user',
    });
  });

  getConversations = task(async () => {
    const model = await this.infinity.model(
      'messaging-conversation',
      {
        include: 'users,last_message',
        ...pageNumberInfinityOptions(),
      },
      pageNumberInfinityModel()
    );

    this.updateConversations(model);
  });

  loadMoreConversations = dropTask(async model => {
    if (!model.canLoadMore) return;
    let data = await this.infinity.loadNextPage(model);

    this.updateConversations(data);
  });

  fetchMessages = task(async () => {
    return await this.infinity.model(
      'messaging-message',
      {
        conversationId: this.messagingNavigation.selectedConversationId,
        include: 'user',
        filter: {
          include_system: this.currentProfile.isClinician, // eslint-disable-line camelcase
        },
        ...pageNumberInfinityOptions(MESSAGES_PER_PAGE),
      },
      pageNumberInfinityModel(MESSAGES_PER_PAGE)
    );
  });

  updateConversations(data) {
    this.conversations = this.infinity.replace(
      data,
      data.filter(conversation => !conversation.disabled)
    );
  }

  async openConversation(id) {
    let users = this.conversations.find(item => item.id === id)?.users;
    this.messagingNavigation.openConversationPage(id, users);
  }
}
