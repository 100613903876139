import { registerDeprecationHandler } from '@ember/debug';

const IGNORED_DEPRECATIONS = [
  'ember-string.add-package', // NOTE: buggy deprecation https://deprecations.emberjs.com/v4.x/#toc_ember-string-add-package
  'ember-data:model-save-promise', // NOTE: fixed in ember-data@4.12  https://github.com/emberjs/data/pull/8435
  'deprecate-ember-error', // NOTE: fixed in ember-data@4.10 https://github.com/emberjs/data/pull/8422
  'component-template-resolving', // NOTE: 3rd party addons like ember-attacher
];

export function initialize() {
  registerDeprecationHandler((message, options, _next) => {
    if (IGNORED_DEPRECATIONS.includes(options.id)) {
      return;
    }

    throw new Error(message);
  });
}

export default { initialize };
