import { and, filterBy, notEmpty, or, reads } from '@ember/object/computed';
import { buildValidations, validator } from 'ember-cp-validations';
import { computed } from '@ember/object';
import { A as emberA } from '@ember/array';
import { fragmentArray } from 'ember-data-model-fragments/attributes';
import { isTestingOrMirage } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import ENV from 'client-portal/config/environment';
import Model, { attr } from '@ember-data/model';
import classic from 'ember-classic-decorator';
import inactiveValidationField from 'ember-simplepractice/utils/macros/inactive-validation-field';

export const pageDescriptionMaxLength = 350;
export const defaultBackgroundColor = '#fff';

export const colorSets = [
  {
    id: '1',
    primary: '#284c61',
    primaryName: 'Navy',
    secondary: '#0b8aa6',
    secondaryName: 'Turquoise',
    filter: 'blue',
  },
  {
    id: '2',
    primary: '#376499',
    primaryName: 'Royal Blue',
    secondary: '#ecb077',
    secondaryName: 'Sepia',
    background: '#fffcf6',
    filter: 'blue',
  },
  {
    id: '3',
    primary: '#4560a8',
    primaryName: 'Berry',
    secondary: '#78aeff',
    secondaryName: 'Periwinkle',
    filter: 'blue',
  },
  {
    id: '4',
    primary: '#2775cd',
    primaryName: 'Blue',
    secondary: '#35bd59',
    secondaryName: 'Green',
    filter: 'blue',
  },
  {
    id: '5',
    primary: '#bc3f23',
    primaryName: 'Ginger',
    secondary: '#ff937b',
    secondaryName: 'Light Salmon',
    background: '#fffcf6',
    filter: 'red',
  },
  {
    id: '6',
    primary: '#f47357',
    primaryName: 'Salmon',
    secondary: '#91a0a1',
    secondaryName: 'Light Gray',
    filter: 'red',
  },
  {
    id: '7',
    primary: '#8b0000',
    primaryName: 'Ruby',
    secondary: '#5a6b76',
    secondaryName: 'Stone',
    filter: 'red',
  },
  {
    id: '8',
    primary: '#e9418d',
    primaryName: 'Fuschia',
    secondary: '#7c38a8',
    secondaryName: 'Violet',
    filter: 'red',
  },
  {
    id: '9',
    primary: '#275555',
    primaryName: 'Hunter Green',
    secondary: '#80c270',
    secondaryName: 'Fern',
    background: '#fffcf6',
    filter: 'green',
  },
  {
    id: '10',
    primary: '#636b4c',
    primaryName: 'Artichoke',
    secondary: '#e3bc30',
    secondaryName: 'Gold',
    filter: 'green',
  },
  {
    id: '11',
    primary: '#82b29a',
    primaryName: 'Mint',
    secondary: '#7075ba',
    secondaryName: 'Iris',
    filter: 'green',
  },
  {
    id: '12',
    primary: '#f4a257',
    primaryName: 'Orange',
    secondary: '#a19991',
    secondaryName: 'Taupe',
    filter: 'orange',
  },
  {
    id: '13',
    primary: '#e2a300',
    primaryName: 'Honey',
    secondary: '#38aff2',
    secondaryName: 'Sky Blue',
    filter: 'orange',
  },
  {
    id: '14',
    primary: '#202020',
    primaryName: 'Black',
    secondary: '#1c3e36',
    secondaryName: 'Dark Pine',
    background: '#fffcf6',
    filter: 'dark-gray',
  },
  {
    id: '15',
    primary: '#2a2a2a',
    primaryName: 'Black',
    secondary: '#9b978d',
    secondaryName: 'Fossil',
    filter: 'dark-gray',
  },
  {
    id: '16',
    primary: '#5a6b76',
    primaryName: 'Stone',
    secondary: '#2e81e2',
    secondaryName: 'True Blue',
    filter: 'dark-gray',
  },
  {
    id: '17',
    primary: '#5a6b76',
    primaryName: 'Stone',
    secondary: '#35855f',
    secondaryName: 'Sea Green',
    filter: 'dark-gray',
  },
  {
    id: '18',
    primary: '#202020',
    primaryName: 'Black',
    secondary: '#70d4f3',
    secondaryName: 'Arctic',
    filter: 'dark-gray',
  },
  {
    id: '19',
    primary: '#285567',
    primaryName: 'Steel Blue',
    secondary: '#e68b02',
    secondaryName: 'Apricot',
    filter: 'blue',
  },
  {
    id: '20',
    primary: '#224043',
    primaryName: 'Spruce',
    secondary: '#ad696b',
    secondaryName: 'Blush',
    background: '#fffcf6',
    filter: 'green',
  },
  {
    id: '21',
    primary: '#206f7b',
    primaryName: 'Ocean',
    secondary: '#819837',
    secondaryName: 'Olive',
    filter: 'green',
  },
  {
    id: '22',
    primary: '#6959ac',
    primaryName: 'Violet',
    secondary: '#379295',
    secondaryName: 'Teal',
    filter: 'purple',
  },
  {
    id: '23',
    primary: '#51305a',
    primaryName: 'Plum',
    secondary: '#aa5a8f',
    secondaryName: 'Raspberry',
    background: '#fffcf6',
    filter: 'purple',
  },
  {
    id: '24',
    primary: '#7f394a',
    primaryName: 'Jam',
    secondary: '#36876e',
    secondaryName: 'Aqua Green',
    filter: 'purple',
  },
].map(colorSet => ({
  background: defaultBackgroundColor,
  disabledButtonColor: colorSet.primary,
  ...colorSet,
}));

export const themes = [
  {
    id: 'casual',
    pageMaxWidth: '1334px',
    websiteLargeButtonBorderRadius: '30px',
    smallButtonBorderRadius: '20px',
    timeSpotButtonBorderRadius: '20px',
    sectionBorderRadius: '0px',
    fonts: {
      header: '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
      body: '-apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu',
    },
  },
  {
    id: 'playful',
    pageMaxWidth: '1334px',
    websiteLargeButtonBorderRadius: '8px',
    smallButtonBorderRadius: '6px',
    timeSpotButtonBorderRadius: '6px',
    sectionBorderRadius: '16px',
    fonts: {
      header: 'Nunito',
      body: 'Roboto',
      source:
        'https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Roboto:wght@400;700&display=swap',
    },
  },
  {
    id: 'modern',
    pageMaxWidth: 'none',
    websiteLargeButtonBorderRadius: '0px',
    smallButtonBorderRadius: '0px',
    timeSpotButtonBorderRadius: '0px',
    sectionBorderRadius: '0px',
    fonts: {
      header: 'Lora',
      body: 'Nunito',
      source:
        'https://fonts.googleapis.com/css2?family=Lora:wght@400;700&family=Nunito:wght@400;700&display=swap',
    },
  },
];

export function presenceValidator(options = {}) {
  let { activeKey } = options;
  return validator('presence', {
    presence: true,
    ignoreBlank: true,
    message: "Can't be left blank.",
    disabled: activeKey ? inactiveValidationField(activeKey) : false,
  });
}

export function maxLengthValidator(length) {
  return validator('length', {
    max: length,
    message: `Can't be more than ${length} characters.`,
  });
}

function socialMediaUrlValidator(name, examplePath) {
  let lowercaseName = name.toLowerCase();
  let regex = new RegExp(`^https?:\/\/(www\.)?${lowercaseName}.com\/`, 'i');
  return validator('format', {
    regex,
    allowBlank: true,
    message: `Enter a valid ${name} link: https://${lowercaseName}.com/${examplePath}`,
  });
}

const Validations = buildValidations({
  practiceName: [presenceValidator(), maxLengthValidator(100), validator('ds-error')],
  practiceCity: [presenceValidator(), maxLengthValidator(40), validator('ds-error')],
  practicePhone: [
    validator('length', {
      allowBlank: true,
      min: 10,
      message: 'Enter a valid 10-digit phone number.',
    }),
    validator('ds-error'),
  ],
  contactEmail: [
    validator('presence', {
      presence: true,
      ignoreBlank: true,
      message: "Can't be left blank.",
      // NOTE: mirrors inactiveValidationField and adds additional logic
      disabled: computed('model.{showContactCta,activeValidationFields}', function () {
        if (
          !this.model.showContactCta ||
          this.model.currentPractice.get('featurePracticeContactForm')
        )
          return true;

        if (this.model.activeValidationFields) {
          return !this.model.activeValidationFields.includes('contactEmail');
        }
        return true;
      }),
    }),
    validator('format', {
      type: 'email',
      allowBlank: true,
      message: 'Enter a valid email',
    }),
    maxLengthValidator(191),
    validator('ds-error'),
  ],
  introTitle: [presenceValidator(), maxLengthValidator(75), validator('ds-error')],
  introBody: [presenceValidator(), maxLengthValidator(350), validator('ds-error')],
  aboutTitle: [maxLengthValidator(75), validator('ds-error')],
  aboutBody: [presenceValidator(), validator('ds-error')],
  profileName: [
    presenceValidator({ activeKey: 'profileName' }),
    maxLengthValidator(100),
    validator('ds-error'),
  ],
  profileTitle: [
    presenceValidator({ activeKey: 'profileTitle' }),
    maxLengthValidator(50),
    validator('ds-error'),
  ],
  facebookUrl: [socialMediaUrlValidator('Facebook', 'username'), validator('ds-error')],
  twitterUrl: [socialMediaUrlValidator('Twitter', 'username'), validator('ds-error')],
  instagramUrl: [socialMediaUrlValidator('Instagram', 'username'), validator('ds-error')],
  yelpUrl: [socialMediaUrlValidator('Yelp', 'biz/username'), validator('ds-error')],
  linkedinUrl: [socialMediaUrlValidator('LinkedIn', 'in/username'), validator('ds-error')],
  youtubeUrl: [socialMediaUrlValidator('YouTube', 'channel/username'), validator('ds-error')],
  locationsTitle: [maxLengthValidator(75), validator('ds-error')],
  marketingTestimonials: [validator('has-many'), validator('ds-error')],
  pageTitle: [presenceValidator(), maxLengthValidator(100), validator('ds-error')],
  pageDescription: [
    presenceValidator(),
    validator('length', {
      max: pageDescriptionMaxLength,
      message: computed('model.pageDescription', function () {
        return `${this.model.pageDescription.length - pageDescriptionMaxLength} characters over`;
      }),
    }),
    validator('ds-error'),
  ],
  specialtiesTitle: [maxLengthValidator(75), validator('ds-error')],
  marketingFees: [validator('has-many'), validator('ds-error')],
});

@classic
export default class PracticeWebsiteModel extends Model.extend(Validations) {
  @service fastboot;
  @service store;
  @service currentPractice;

  defaultBackgroundColor = defaultBackgroundColor;

  // NOTE: marketingSpecialties are now referred to as "Services" in the presentation
  @fragmentArray('practice-website/marketing-specialty') marketingSpecialties;
  @fragmentArray('practice-website/marketing-insurance-provider') marketingInsuranceProviders;
  // NOTE: marketingServices are now referred to as "Approaches" in the presentation
  @fragmentArray('practice-website/marketing-service') marketingServices;
  @fragmentArray('practice-website/marketing-testimonial') marketingTestimonials;
  @fragmentArray('practice-website/marketing-fee') marketingFees;

  @attr() practiceName;
  @attr() practiceLogoId;
  @attr() practiceNppId;
  @attr() practiceCity;
  @attr() practicePhone;
  @attr() contactEmail;
  @attr() colorSetId;
  @attr('boolean') showSectionSpecialties;
  @attr('boolean') showSectionTestimonials;
  @attr('boolean') showSectionLocations;
  @attr('boolean') showLoginLink;
  @attr('boolean') showContactCta;
  @attr('boolean') showRequestAppointmentLink;
  @attr() theme;
  @attr({ defaultValue: '' }) introImg;
  @attr() introTitle;
  @attr() introBody;
  @attr('number') introReadability;
  @attr() uploadedIntroImgId;
  @attr('boolean') telehealthIndicator;
  @attr('boolean') onlinePaymentsIndicator;
  @attr('boolean') acceptInsuranceIndicator;
  @attr('boolean') acceptNewClientsIndicator;
  @attr() aboutTitle;
  @attr() aboutBody;
  @attr() profileImgId;
  @attr() profileName;
  @attr() profileTitle;
  @attr() specialtiesTitle;
  @attr() selectedOfficeIds; // Array[String]
  @attr() locationsTitle;
  @attr() facebookUrl;
  @attr() twitterUrl;
  @attr() instagramUrl;
  @attr() yelpUrl;
  @attr() youtubeUrl;
  @attr() linkedinUrl;
  @attr() pageTitle;
  @attr() pageDescription;
  @attr('boolean') showNoSurprisesAct;
  @attr('boolean') practiceContactForm;

  @reads('currentPractice.requestAsNewClient') isRequestAppointmentLinkEnabled;

  @computed('introImg')
  get hasPatternBackground() {
    return !!this.introImg?.startsWith('p');
  }

  @computed('introImg')
  get hasUploadBackground() {
    return !!this.introImg?.startsWith('u');
  }

  @computed('introImg')
  get hasGalleryBackground() {
    return /^\d/.test(this.introImg);
  }

  @computed('colorSetId')
  get colorSet() {
    return colorSets.findBy('id', this.colorSetId);
  }

  @reads('colorSet.primary') primaryColor;
  @reads('colorSet.secondary') secondaryColor;
  @or('colorSet.background', 'defaultBackgroundColor') backgroundColor;

  @computed('theme')
  get themeSet() {
    return themes.findBy('id', this.theme);
  }

  @notEmpty('marketingTestimonials') hasMarketingTestimonials;
  @notEmpty('marketingSpecialties') hasMarketingSpecialties;
  @notEmpty('marketingInsuranceProviders') hasMarketingInsuranceProviders;
  @notEmpty('marketingServices') hasMarketingServices;
  @notEmpty('marketingFees') hasMarketingFees;
  @notEmpty('selectedOfficeIds') hasSelectedOfficeIds;

  @filterBy('marketingTestimonials', 'isPresent') filteredMarketingTestimonials;

  @or(
    'hasMarketingSpecialties',
    'hasMarketingInsuranceProviders',
    'hasMarketingServices',
    'hasMarketingFees'
  )
  hasSpecialtiesSectionContent;

  @and('currentPractice.hasCustomStripeAccount', 'onlinePaymentsIndicator')
  showOnlinePaymentsIndicator;
  @or(
    'telehealthIndicator',
    'showOnlinePaymentsIndicator',
    'acceptInsuranceIndicator',
    'acceptNewClientsIndicator'
  )
  hasHighlightIcons;

  @computed('practiceLogoId')
  get practiceLogoUrl() {
    return this.urlForUploadedFileProperty('practiceLogoId');
  }

  @computed('uploadedIntroImgId')
  get uploadedIntroImgUrl() {
    return this.urlForUploadedFileProperty('uploadedIntroImgId', { getAdjusted: true });
  }

  @computed('uploadedIntroImgId')
  get uploadedIntroImgThumbUrl() {
    return this.urlForUploadedFileProperty('uploadedIntroImgId');
  }

  urlForUploadedFileProperty(property, adapterParams = {}) {
    let uploadedFileId = this[property];

    if (!uploadedFileId) {
      return null;
    }

    if (this._isTestingOrMirage) {
      return '/test-fixtures/test-logo.png';
    }

    return this.store
      .adapterFor('practice-website-upload')
      .urlForUploadedFile(uploadedFileId, adapterParams);
  }

  @computed('practiceNppId')
  get practiceNppUrl() {
    if (!this.practiceNppId) {
      return null;
    }

    if (this._isTestingOrMirage) {
      return '/test-fixtures/test-pdf.pdf';
    }

    return this.store.adapterFor('practice-website-upload').urlForUploadedFile(this.practiceNppId);
  }

  @computed('profileImgId')
  get profileImgUrl() {
    if (!this.profileImgId) {
      return null;
    }

    if (this._isTestingOrMirage) {
      return '/test-fixtures/test-profile.jpg';
    }

    return this.store.adapterFor('practice-website-upload').urlForUploadedFile(this.profileImgId);
  }

  get _isTestingOrMirage() {
    if (this.fastboot.isFastBoot) {
      return false;
    }

    return isTestingOrMirage() && ENV.railsEnv !== 'test';
  }

  static fieldsFor(_context) {
    let fields = emberA();
    this.fields.forEach((_, v) => fields.pushObject(v));
    fields.removeObject('meta');
    return fields;
  }
}
