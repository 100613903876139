export function setControllerFullscreenStatus(controller, setFullscreen) {
  let appearance = controller.appearance;
  let fullScreen = false;
  if (appearance) {
    let hash = { fullScreen: setFullscreen };
    try {
      hash = JSON.parse(appearance);
    } catch (err) {
      // noop
    }

    fullScreen = hash.fullScreen;
  }
  controller.set('fullScreen', fullScreen);
}
