import { dasherize, underscore } from '@ember/string';
import JSONAPISerializer from '@ember-data/serializer/json-api';

export default class MessagingBase extends JSONAPISerializer {
  keyForAttribute(attr) {
    return underscore(attr);
  }

  keyForRelationship(relationshipName) {
    return underscore(relationshipName);
  }

  modelNameFromPayloadKey(payloadType) {
    return super.modelNameFromPayloadKey(`messaging-${dasherize(payloadType)}`);
  }

  payloadKeyFromModelName(modelName) {
    return underscore(super.payloadKeyFromModelName(modelName.replace('messaging-', '')));
  }
}
