import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { analyticsEnabled, shouldShowBanner } from '../utils/datagrail';
import { getOwner } from '@ember/application';
import { isTest } from 'ember-simplepractice/utils/is-testing';
import { modifier } from 'ember-modifier';
import { on } from '@ember/modifier';
import { tracked } from '@glimmer/tracking';
import { waitForLater } from 'ember-simplepractice/utils/waiters';
import Component from '@glimmer/component';
import styles from './dg-consent-banner.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
/**
 * A banner that displays a message about data collection and consent.
 * @class DgConsentBanner
 * @public
 */ export default class DgConsentBanner extends Component {
    @tracked
    showBanner = false;
    isTest = isTest();
    constructor(){
        super(...arguments);
        this.initDatagrail();
    }
    get privacyPolicyIsHttps() {
        return this.privacyPolicyRoute.indexOf('https:') > -1;
    }
    get fastboot() {
        return getOwner(this).lookup('service:fastboot');
    }
    startDisplayTimer = modifier(this.checkShouldShowBanner, {
        eager: false
    });
    checkForConsent(preferences1) {
        if (window.mixpanel && !analyticsEnabled(preferences1)) {
            window.mixpanel.reset();
        }
    }
    initDatagrail = ()=>{
        window.dgEvent = window.dgEvent || [];
        window.dgEvent.push({
            event: 'preference_callback',
            params: this.checkForConsent
        });
        window.dgEvent.push({
            event: 'initial_preference_callback',
            params: this.checkForConsent
        });
    };
    @action
    closeBanner() {
        this.showBanner = false;
        document.cookie = 'datagrail_consent_preferences=dg-category-analytics:1|dg-category-essential:1|dg-category-personalization:1|dg-category-targeted-advertising:1';
    }
    @action
    async checkShouldShowBanner() {
        this.showBanner = shouldShowBanner(this.fastboot?.isFastBoot);
        if (!this.isTest) {
            await waitForLater(10000);
            this.closeBanner();
        }
    }
    static{
        template(`
    <div {{this.startDisplayTimer}}>
      {{#if this.showBanner}}
        <div class={{styles.component}}>
          <div class="consent-text" data-test-consent-text>
            This website collects data using technology such as cookies to enable essential site
            functionality. It also uses data for analytics and personalization. Learn more in the
            <a
              href="https://www.simplepractice.com/privacy/"
              target="_blank"
              rel="noopener noreferrer"
              data-test-privacy-policy-anchor
            >Privacy Policy</a>.
          </div>
          <button
            class="consent-banner-close-button"
            type="button"
            {{on "click" this.closeBanner}}
            data-test-consent-banner-close-button
          >
            {{svgJar "x-mark"}}
          </button>
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
