import { EXTERNAL_SCOPE_TYPES } from './messaging-user';
import { equal } from 'macro-decorators';
import Model, { attr, belongsTo } from '@ember-data/model';

export default class MessagingProfileModel extends Model {
  @belongsTo('messaging-system-user', { async: false, inverse: null }) systemUser;

  @equal('externalScope', EXTERNAL_SCOPE_TYPES.user) isClinician;

  @attr('boolean') isAway;
  @attr('boolean') isSuperUser;

  @attr('string') awayMessageText;
  @attr('string') externalScope;
  @attr('string') name;

  @attr({ defaultValue: () => [] }) unreadConversationIds;

  addUnreadConversation(conversationId) {
    const stringifiedId = `${conversationId}`;
    if (this.unreadConversationIds.indexOf(stringifiedId) === -1) {
      this.unreadConversationIds.push(stringifiedId);
    }
  }

  removeUnreadConversation(conversationId) {
    this.unreadConversationIds = this.unreadConversationIds.filter(
      id => id !== `${conversationId}`
    );
  }
}
