import {
  ActionValidation,
  BooleanValidation,
  ConfigOptionValidation,
  StringValidation,
} from '../../../utils/component-validations';
import { action } from '@ember/object';
import { on } from '@ember/modifier';
import { or } from 'ember-truth-helpers';
import { testAwareWaitForLater } from '../../../utils/waiters';
import { tracked } from '@glimmer/tracking';
import Body from './message/body.gjs';
import Button from '../core/button.gjs';
import CTA from './message/cta.gjs';
import Container from '../core/container.gjs';
import Super from '../core/base.gjs';
import styles from './message.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';

const COLUMN = 'column';
const CTA_ALIGNMENTS = [COLUMN, 'row'];
const SUPPORTED_TYPES = ['error', 'info', 'success', 'warning'];

export default class SpdsAlertBannerMessage extends Super {
  arguments = [
    new ActionValidation({ optional: ['onClose'] }),
    new BooleanValidation({ optional: ['closeable', 'includeIcon'] }),
    new ConfigOptionValidation({
      optional: [
        {
          name: 'ctaAlignment',
          options: CTA_ALIGNMENTS,
        },
      ],
      required: [
        {
          name: 'type',
          options: SUPPORTED_TYPES,
        },
      ],
    }),
    new StringValidation({ optional: ['icon'] }),
  ];
  baseClass = 'spds-alert-banner-message-container';
  classesToMapToArgs = ['global', 'type'];
  classesToMapToAttrs = ['open'];
  componentName = 'Spds::AlertBanner::Message';
  styles = styles;

  @tracked open = true;

  get ctaAlignment() {
    return this.args.ctaAlignment === COLUMN;
  }

  get icon() {
    let { icon, includeIcon, type } = this.args;

    if (icon) return icon;

    if (includeIcon) {
      switch (type) {
        case 'error':
          return 'triangle-exclamation-mark';
        case 'success':
          return 'circle-check';
        default:
          return 'circle-i';
      }
    }

    return null;
  }

  get showCloseBtn() {
    let { closeable, global, onClose } = this.args;

    return (closeable && global) || onClose;
  }

  @action
  async close() {
    this.open = false;

    await testAwareWaitForLater(300);

    this.args.onClose?.();
  }

  <template>
    <Container
      @fitContentHeight={{true}}
      class={{this.classes}}
      data-test-spds-alert-banner-message-container
      {{this.validateArguments}}
      ...attributes
    >
      <Container
        @fitContentHeight={{true}}
        class="spds-alert-banner-message"
        data-test-spds-alert-banner-message
      >
        {{#if this.icon}}
          {{svgJar this.icon class="spds-alert-banner-icon" data-test-spds-alert-banner-icon=""}}
        {{/if}}
        <Container
          @column={{this.ctaAlignment}}
          class="spds-alert-banner-body"
          data-test-spds-alert-banner-body
        >
          <Container
            @column={{true}}
            class="spds-alert-banner-text-container"
            data-test-spds-alert-banner-text-container
          >
            {{#if (or (has-block "header") @header)}}
              <Container
                @content={{@header}}
                @tag="h5"
                class="spds-alert-banner-header"
                data-test-spds-alert-banner-header
              >
                {{yield to="header"}}
              </Container>
            {{/if}}
            <Container
              @column={{true}}
              @tag="p"
              class="spds-alert-banner-body-text"
              data-test-spds-alert-banner-body-text
            >
              {{#if (has-block "body")}}
                {{yield to="body"}}
              {{else}}
                <Body @body={{@body}} />
              {{/if}}
            </Container>
          </Container>
          <Container
            class="spds-alert-banner-cta-container"
            data-test-spds-alert-banner-cta-container
          >
            {{#if (has-block "cta-container")}}
              {{yield to="cta-container"}}
            {{else}}
              <CTA
                @onPrimaryBtnClick={{@onPrimaryBtnClick}}
                @onSecondaryBtnClick={{@onSecondaryBtnClick}}
                @primaryBtnText={{@primaryBtnText}}
                @secondaryBtnText={{@secondaryBtnText}}
              />
            {{/if}}
          </Container>
        </Container>
        {{#if this.showCloseBtn}}
          <Button
            aria-label="close"
            class="spds-alert-banner-close button ghost"
            data-test-spds-alert-banner-close-button
            type="button"
            {{on "click" this.close}}
          >
            {{svgJar "x-mark"}}
          </Button>
        {{/if}}
      </Container>
    </Container>
  </template>
}
