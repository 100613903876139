import { action, setProperties } from '@ember/object';
import Component from '@glimmer/component';

export default class SharedQuestionnaireShortAnswerInput extends Component {
  @action
  handleSetValue(value) {
    let isDirty = !this.args.isModelNew || Boolean(value);
    setProperties(this.args.answer, { isDirty, freeTextAnswer: value });
  }

  get textAnswers() {
    return this.args.question.intakeAnswers.mapBy('text');
  }
}
