export const DAY_HOURS = 24 * 60 * 60 * 1000;
export const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

export function getMessageDateLabel(dateKey) {
  const givenDate = new Date(dateKey);
  const today = new Date();
  const yesterday = new Date(today.getTime() - DAY_HOURS);

  if (givenDate.toDateString() === today.toDateString()) {
    return capitalized(rtf.format(0, 'day'));
  } else if (givenDate.toDateString() === yesterday.toDateString()) {
    return capitalized(rtf.format(-1, 'day'));
  } else {
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour12: true,
    }).format(givenDate);
  }
}

export function formatDate(date) {
  const timeFormatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  return timeFormatter.format(new Date(date));
}

export const capitalized = str => str.charAt(0).toUpperCase() + str.slice(1);
