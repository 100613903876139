import * as ActionCable from '@rails/actioncable';
import { getOwner } from '@ember/application';
import { isTest } from 'ember-simplepractice/utils/is-testing';
import { tracked } from '@glimmer/tracking';
import ConversationHandler from './activity-handlers/conversation';
import Service, { service } from '@ember/service';
import emberConfig from 'ember-get-config';

if (emberConfig.environment === 'development' && !isTest()) {
  ActionCable.logger.enabled = true;
}

export default class WebsocketMessagingWebsocketService extends Service {
  @service messaging;
  @service store;

  @tracked isConnected = false;

  conversationHandler;
  socket;

  constructor() {
    super(...arguments);
    this.#initHandlers();
  }

  openSocket(messagingApiUrl, token) {
    const socketUrl = this.#buildSocketUrl(messagingApiUrl, token);
    this.socket = ActionCable.createConsumer(socketUrl);
    this.socket.subscriptions.create(
      { channel: 'ActivityChannel' },
      {
        connected: () => {
          this.isConnected = true;
          // Reconnection logic will go here.
        },
        disconnected: () => {
          this.isConnected = false;
        },
        received: response => {
          this.handleActivity(response);
        },
      }
    );
  }

  closeSocket() {
    this.socket?.disconnect();
  }

  handleActivity(response) {
    switch (response.action) {
      case 'conversation':
        this.conversationHandler.handleActivity(response);
        break;
      case 'message':
        this.handleMessageActivity();
        break;
      case 'contacts':
        this.handleContactsActivity();
        break;
      default:
        return false;
    }
  }

  handleMessageActivity() {
    // Consider kicking this out to a handler class if sufficiently complicated.
  }

  handleContactsActivity() {
    // Consider kicking this out to a handler class if sufficiently complicated.
  }

  #initHandlers() {
    this.conversationHandler = new ConversationHandler(getOwner(this));
  }

  #buildSocketUrl(messagingApiUrl, token) {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const host = new URL(messagingApiUrl).host;
    return `${protocol}//${host}/websocket?access_token=${token}`;
  }
}
