import { service } from '@ember/service';
import { setOwner } from '@ember/application';

export default class WebsocketActivityHandlersConversation {
  @service messaging;
  @service store;

  constructor(owner) {
    setOwner(this, owner);
  }

  handleActivity(response) {
    const statusHandlers = {
      updated: this.#handleUpdatedActivity,
      read: this.#handleReadActivity,
      created: this.#handleCreationActivity,
    };
    statusHandlers[response.status]?.call(this, response.payload);
  }

  #handleUpdatedActivity(payload) {
    const conversation = this.store.normalize('messaging-conversation', payload.data).data;
    const record = this.store.peekRecord('messaging-conversation', conversation.id);
    if (record) {
      record.setProperties(conversation.attributes);
    }
  }

  #handleReadActivity(payload) {
    const conversation = this.store.normalize('messaging-conversation', payload.data).data;
    this.messaging.currentProfile?.removeUnreadConversation(conversation.id);
  }

  #handleCreationActivity(payload) {
    this.store.pushPayload('messaging-conversation', payload);
    const record = this.store.peekRecord('messaging-conversation', payload.data.id);
    this.messaging.conversations?.unshiftObjects([record]);
  }
}
