import { bannerError } from 'client-portal/utils/error-handling';
import { reads } from 'macro-decorators';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import InfinityModel from 'ember-infinity/lib/infinity-model';
import Service, { service } from '@ember/service';

const PAGE_SIZE = 30;

class ExtendedInfinityModel extends InfinityModel {
  buildParams() {
    let params = super.buildParams(...arguments);
    params.page = { before: this.before, size: PAGE_SIZE };
    return params;
  }

  afterInfinityModel(model) {
    this.canLoadMore = model.length >= PAGE_SIZE;
    this.before = model.lastObject?.id;
  }
}

export default class AnnouncementService extends Service {
  @service currentPractice;
  @service infinity;
  @service router;
  @service store;

  @tracked announcementsInfinityModel = [];

  @reads('queryAnnouncements.isRunning') isLoading;

  get isAnnouncementsActive() {
    let isClientSelectionPage = this.router.currentRouteName === 'client-selection';
    return !isClientSelectionPage && this.currentPractice.get('announcementsAvailable');
  }

  async fetchAnnouncements() {
    if (this.isAnnouncementsActive) {
      await this.queryAnnouncements.perform();
    }
  }

  async markAnnouncementsAsRead() {
    if (this.announcementsInfinityModel.any(announcement => announcement.readAt === null)) {
      let adapter = this.store.adapterFor('announcement');
      await adapter.readAnnouncements();

      this.infinity.replace(
        this.announcementsInfinityModel,
        this.announcementsInfinityModel.content.map(announcement => {
          if (announcement.readAt === null) {
            announcement.readAt = new Date().toISOString();
          }
          return announcement;
        })
      );
    }
  }

  queryAnnouncements = task(async () => {
    try {
      this.announcementsInfinityModel = await this.infinity.model(
        'announcement',
        {
          perPage: PAGE_SIZE,
          perPageParam: null,
          pageParam: null,
        },
        ExtendedInfinityModel
      );
    } catch (err) {
      bannerError(err, { title: 'Please reload and try again' });
    }
  });
}
