import { decodeText, decodeTextWithLinks } from '../utils/messaging/format-text';
import { formatDate } from '../utils/messaging/format-date';
import { service } from '@ember/service';
import Component from '@glimmer/component';

const CONVERSATION_TRUNCATED_MESSAGE = 'CONVERSATION_TRUNCATED';

export default class ConversationMessage extends Component {
  @service messaging;
  @service intl;

  get parsedSystemMessage() {
    let { text, createdAt } = this.args.message;

    if (text !== CONVERSATION_TRUNCATED_MESSAGE) {
      return decodeText(
        text.replace(
          'on %created_at%',
          `at ${formatDate(new Date(createdAt))}`.replaceAll(' ', '&nbsp;')
        )
      );
    }

    let footer = this.messaging.currentProfile.isSuperUser
      ? this.intl.t('truncated_system_message.footer')
      : '';

    return this.intl.t('truncated_system_message.text', {
      date: formatDate(new Date(createdAt)),
      footer,
    });
  }

  <template>
    <div
      class="message-wrapper {{if @message.isOwn 'own'}} {{if @message.isSystem 'system'}}"
      data-test-conversation-page-message
    >
      {{#if @message.isSystem}}
        <span class="message-text">{{this.parsedSystemMessage}}</span>
      {{else}}
        {{#unless @message.isOwn}}
          <div class="avatar">
            <span class="initials">{{@message.user.initials}}</span>
          </div>
        {{/unless}}
        <div class="message">
          <span class="message-text">{{decodeTextWithLinks @message.text}}</span>
          <div class="message-info">
            {{#if @message.isOwn}}
              <span>Sent</span>
            {{else}}
              <span>{{@message.user.name}}</span>
            {{/if}}
            <span class="time">· {{formatDate @message.createdAt}}</span>
          </div>
        </div>
      {{/if}}
    </div>
  </template>
}
