import { htmlSafe } from '@ember/template';
import LinkifyIt from 'linkify-it';
import he from 'he';

const linkify = new LinkifyIt();

export function decodeText(str) {
  if (!str) return '';

  return he.decode(str);
}

export function decodeTextWithLinks(str) {
  if (!str) return '';

  let decodedText = he.decode(str);
  let escapedText = he.encode(decodedText);

  let linkedText =
    linkify.match(escapedText)?.reduce((acc, match) => {
      let linkHtml = `<a href="${match.url}" target="_blank" rel="noopener noreferrer">${match.text}</a>`;
      return acc.replace(match.text, linkHtml);
    }, escapedText) || escapedText;

  return htmlSafe(linkedText.replace(/\n/g, '<br>'));
}
