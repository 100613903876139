import { action } from '@ember/object';
import { confirmSetupIntent } from 'client-portal/utils/stripe-payment-methods';
import { or } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import AdapterError from '@ember-data/adapter/error';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class StripeCardsService extends Service {
  @service store;
  @service session;
  @service stripe;
  @service analytics;

  @or('saveCardTask.isRunning', 'createCardTokenTask.isRunning') areTasksRunning;
  @or('saveCardTask.isRunning', 'addPaymentMethodTask.isRunning') areNewPaymentMethodTasksRunning;

  @action
  createCard() {
    return this.store.createRecord('card', {
      name: this.session.currentClientAccess.client.preferredName,
      isDefault: true,
    });
  }

  @action
  async createTokenAndSave(card) {
    try {
      await this.createCardTokenTask.perform(card);
      await this.saveCardTask.perform(card);
    } catch (err) {
      throw new AdapterError(err.errors);
    }
  }

  analyticsErrorTracking(message) {
    this.analytics.track('Client Payment Method Added', {
      object: 'Client Payment Method',
      action: 'Added',
      initiator: 'User',
      from: 'New card',
      'is_mobile_app': false,
      errors: [message],
      'sp_client_id': this.session.currentClient.hashedId,
      'added_successfully': false,
      'payment_type': 'Stripe',
    });
  }

  @(task(function* (card) {
    card.set('_isDirty', true);

    if (card.isNew) {
      yield card.validate();
      yield card.createToken();

      if (card.validations.isInvalid) {
        yield Promise.reject();
      }
    }
  }).drop())
  createCardTokenTask;

  @(task(function* (card) {
    yield card.save({
      adapterOptions: {
        embed: ['card'],
      },
    });
  }).drop())
  saveCardTask;

  @(task(function* ({ elements, newCard, persistCard = true }) {
    let options = {
      paymentMethodTypes: ['card'],
    };

    if (this.session.currentClient) {
      options.client = this.session.currentClient;
    }

    let newSetupIntent = this.store.createRecord('stripe-setup-intent', options);
    yield newSetupIntent.save();

    let setupIntentConfirmation = yield confirmSetupIntent(
      newSetupIntent,
      elements,
      this.stripe.stripe,
      {
        cardholderName: newCard.name,
        errorTracking: this.analyticsErrorTracking.bind(this),
      }
    );

    if (setupIntentConfirmation) {
      let { payment_method: paymentMethod } = setupIntentConfirmation;

      let { card, customer } = paymentMethod;

      newCard.setProperties({
        customStripeCustomerId: customer,
        brand: card.brand,
        last4: card.last4,
        expMonth: card.exp_month,
        expYear: card.exp_year,
        paymentMethodId: paymentMethod.id,
      });

      if (persistCard) {
        yield this.saveCardTask.perform(newCard);
      }

      return newCard;
    }
  }).drop())
  addPaymentMethodTask;
}
