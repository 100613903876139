import { PROFESSIONAL_WEBSITE } from './session';
import { and, bool, equal, match, reads } from '@ember/object/computed';
import { computed } from '@ember/object';
import {
  colorSets as websiteColorSets,
  themes as websiteThemes,
} from 'client-portal/models/practice-website';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

export const COMPSYCH_CHANNEL_NAME = 'compsych';
export const FLOURISHLABS_CHANNEL_NAME = 'flourishlabs';
export const THEME_ID_MONARCH = 'monarch';
export const THEME_ID_WEBSITE = 'pw';
export const THEME_ID_COMPSYCH = 'compsych';

const DEFAULT_BODY_TEXT_COLOR = '#333333'; // $token-color-neutral-800
const DEFAULT_PRIMARY_SPECIAL_COLOR = '#44ab89';
const DEFAULT_PRIMARY_SPECIAL_BUTTON_COLOR = '#ffffff';
const DEFAULT_SMALL_BUTTON_BORDER_RADIUS = '2px';
const DEFAULT_MEDIUM_BUTTON_BORDER_RADIUS = '6px';
const DEFAULT_TIME_SPOT_BUTTON_BORDER_RADIUS = '15px';
export const FALLBACK_FONTS = '"Helvetica Neue", Helvetica, Arial, sans-serif';

export const GLOBAL_COLOR_SETS = [
  {
    id: THEME_ID_MONARCH,
    primary: '#007f41', // $token-color-grass-500
    secondary: '#464e54', // $tolen-color-stone-800
    bodytext: DEFAULT_BODY_TEXT_COLOR,
    background: '#ffffff',
    primarySpecial: '#007f41',
  },
  {
    id: THEME_ID_COMPSYCH,
    primary: '#003e74', // $token-color-grass-500
    secondary: '#464e54', // $token-color-stone-800
    bodytext: DEFAULT_BODY_TEXT_COLOR,
    background: '#ffffff',
    primarySpecial: '#ffffff',
    specialButtonColor: '#003e74',
    specialButtonBorderColor: '#003e74',
    disabledButtonColor: '#919191',
  },
  ...websiteColorSets,
].map(colorSet => ({
  bodytext: DEFAULT_BODY_TEXT_COLOR,
  primarySpecial: DEFAULT_PRIMARY_SPECIAL_COLOR,
  specialButtonColor: DEFAULT_PRIMARY_SPECIAL_BUTTON_COLOR,
  specialButtonBorderColor: DEFAULT_PRIMARY_SPECIAL_BUTTON_COLOR,
  disabledButtonColor: colorSet.primary,
  ...colorSet,
}));

export const GLOBAL_THEME_SETS = [
  {
    id: THEME_ID_MONARCH,
  },
  {
    id: THEME_ID_COMPSYCH,
  },
  ...websiteThemes,
].map(themeSet => ({
  smallButtonBorderRadius: DEFAULT_SMALL_BUTTON_BORDER_RADIUS,
  mediumButtonBorderRadius: DEFAULT_MEDIUM_BUTTON_BORDER_RADIUS,
  timeSpotButtonBorderRadius: DEFAULT_TIME_SPOT_BUTTON_BORDER_RADIUS,
  ...themeSet,
}));

@classic
export default class StyleVariablesService extends Service {
  @service router;
  @service session;
  @service currentPractice;

  globalColorSets = GLOBAL_COLOR_SETS;
  globalThemeSets = GLOBAL_THEME_SETS;

  @reads('router.currentRouteName') currentRouteName;
  @bool('practiceWebsite') hasPracticeWebsite;
  @bool('router.currentRoute.queryParams.edit') isEditingWebsite;
  @match('currentRouteName', /home/) isHomeRoute;
  @and('isMonarchThemedChannel', 'isWidgetRoute') isMonarchWidget;
  @equal('session.channel', PROFESSIONAL_WEBSITE) isWebsiteChannel;
  @and('isWebsiteChannel', 'isWidgetRoute', 'hasPracticeWebsite') isWebsiteWidget;
  @match('currentRouteName', /widget/) isWidgetRoute;
  @reads('currentPractice.practiceWebsite') practiceWebsite;
  @reads('currentPractice.websitePublished') websitePublished;
  @reads('session.colorSetId') previewWebsiteColorSetId;
  @reads('session.theme') previewWebsiteTheme;

  @computed('isEditingWebsite', 'isHomeRoute', 'practiceWebsite', 'websitePublished')
  get isPracticeWebsiteRoute() {
    return (
      this.isHomeRoute && (this.websitePublished || this.isEditingWebsite) && !!this.practiceWebsite
    );
  }

  get isCompsychChannel() {
    return COMPSYCH_CHANNEL_NAME === this._parentChannelName();
  }

  @computed(
    'isMonarchWidget',
    'isPracticeWebsiteRoute',
    'isWebsiteWidget',
    'isWidgetRoute',
    'practiceWebsite'
  )
  get themeSource() {
    if (this.isPracticeWebsiteRoute || this.isWebsiteWidget) {
      return THEME_ID_WEBSITE;
    } else if (this.isCompsychChannel) {
      return THEME_ID_COMPSYCH;
    } else {
      return THEME_ID_MONARCH;
    }
  }

  @computed('session.channel')
  get isMonarchThemedChannel() {
    return [THEME_ID_MONARCH, FLOURISHLABS_CHANNEL_NAME].includes(this._parentChannelName());
  }

  @computed('themeSource', 'practiceWebsite.colorSetId')
  get colorSet() {
    let { themeSource } = this;
    let id = themeSource;

    if (themeSource === THEME_ID_WEBSITE) {
      id = this.previewWebsiteColorSetId || this.practiceWebsite.colorSetId;
    }

    return this.globalColorSets.findBy('id', id);
  }

  @computed('themeSource', 'practiceWebsite.theme')
  get themeSet() {
    let { themeSource } = this;
    let id = themeSource;

    if (themeSource === THEME_ID_WEBSITE) {
      id = this.previewWebsiteTheme || this.practiceWebsite.theme;
    }

    return this.globalThemeSets.findBy('id', id);
  }

  @computed('colorSet', 'themeSet')
  get globalTheme() {
    return {
      ...this.themeSet,
      ...this.colorSet,
      id: this.themeSource,
    };
  }

  _parentChannelName() {
    return this.session.channel?.split('_')[0];
  }
}
