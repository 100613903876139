/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import { MAX_MESSAGE_LENGTH } from 'client-portal/models/reservation';
import { action, computed } from '@ember/object';
import { classNames } from '@ember-decorators/component';
import { reads } from '@ember/object/computed';
import { service } from '@ember/service';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';
import styles from './form.module.scss';

export const THERAPY_REASONS = [
  'Anxiety',
  'Attentional difficulties',
  'Behavioral issues',
  'Depression',
  'Grief',
  'Relationship issues',
  'Substance use',
  'Trauma',
  'Other',
];

export const MENTAL_HEALTH_ASSESSMENT_OPTIONS = [
  'In therapy now',
  'In therapy in the past',
  'Taking psychiatric medication now',
  'Taken psychiatric medication in the past',
  'Hospitalized for mental health reasons now or recently',
  'Hospitalized for mental health reasons in the past',
  'Known neurologic or genetic disorder',
  'Attempted suicide in the past',
  'None of these apply',
];

export const CARE_TYPES_OPTIONS = ['Psychotherapy', 'Medication', 'Other', 'Not sure'];

export const MAX_NUMBER_OF_SELECTIONS = 3;

export const PSYCHIATRY = 'Psychiatry';
export const PSYCHIATRY_MENTAL_HEALTH_NURSING = 'Psychiatric-Mental Health Nursing';

export const MENTAL_HEALTH_HISTORY_MODEL = 'mentalHealthHistory';
export const CARE_TYPES_MODEL = 'careTypes';
@classic
@classNames(styles.component)
export default class RequestMessageForm extends Component {
  @service session;
  @service currentPractice;

  @reads('model.reasonsForVisit') selectedReasons;
  @reads('model.message') message;
  @reads('model.validations.attrs.message.isInvalid') isInvalid;
  @reads('session.reorderedNavEnabled') isReorderedNavEnabled;
  @reads('session.addReasonsForVisit') isReasonsForVisitEnabled;
  @reads('model.mentalHealthHistory') selectedMentalHealthOptions;
  @reads('model.careTypes') selectedCareTypesOptions;
  @reads('model.clinician.globalProfessionName') globalProfessionName;
  @reads('model.clinician.hasMonarchProfession') hasMonarchProfession;
  @reads('currentPractice.featureBookingWidgetMedicationQuestionEnabled')
  featureBookingWidgetMedicationQuestion;
  @reads('currentPractice.featureExpandPrescreenerQuestions') featureExpandPrescreenerQuestions;
  @reads('currentPractice.featureLaWildfirePrescreenerBanner') showFireWarning;

  mapOptionsToCheckboxes(options, collection) {
    return options.map(option => ({
      option,
      checked: collection.includes(option),
    }));
  }

  @computed('selectedReasons.[]')
  get canAddReasons() {
    return this.selectedReasons.length < MAX_NUMBER_OF_SELECTIONS;
  }

  @computed(
    'selectedReasons.[]',
    'selectedMentalHealthOptions.[]',
    'message',
    'selectedCareTypesOptions.[]'
  )
  get willShowDisclaimer() {
    return (
      this.selectedReasons.length ||
      this.selectedMentalHealthOptions.length ||
      this.message ||
      this.selectedCareTypesOptions.length
    );
  }

  get therapyReasons() {
    return THERAPY_REASONS;
  }

  get mentalHealthAssessmentOptions() {
    return MENTAL_HEALTH_ASSESSMENT_OPTIONS;
  }

  get careTypesOptions() {
    return CARE_TYPES_OPTIONS;
  }

  get mentalHealthAssessmentModel() {
    return MENTAL_HEALTH_HISTORY_MODEL;
  }

  get careTypesModel() {
    return CARE_TYPES_MODEL;
  }

  @computed('selectedMentalHealthOptions.[]')
  get mentalHealthAssessmentCollection() {
    return this.selectedMentalHealthOptions;
  }

  @computed('selectedCareTypesOptions.[]')
  get careTypesCollection() {
    return this.selectedCareTypesOptions;
  }

  @action
  toggleReason(reason) {
    if (this.selectedReasons.includes(reason)) {
      this.model.set(
        'reasonsForVisit',
        this.selectedReasons.filter(r => r !== reason)
      );
    } else if (this.canAddReasons) {
      this.selectedReasons.pushObject(reason);
    }
  }

  @action
  toggleOption(collection, model, option) {
    if (collection.includes(option)) {
      this.model.set(
        model,
        collection.filter(r => r !== option)
      );
    } else {
      collection.pushObject(option);
    }
  }

  @computed('selectedReasons.[]')
  get reasonCheckboxes() {
    return this.therapyReasons.map(reason => ({
      reason,
      checked: this.selectedReasons.includes(reason),
      disabled: !this.canAddReasons && !this.selectedReasons.includes(reason),
    }));
  }

  @computed('selectedMentalHealthOptions.[]')
  get mentalHealthCheckboxes() {
    return this.mapOptionsToCheckboxes(
      this.mentalHealthAssessmentOptions,
      this.mentalHealthAssessmentCollection
    );
  }

  @computed('message')
  get counterMessage() {
    let { message } = this;

    return message?.length
      ? `${message.length}/${MAX_MESSAGE_LENGTH} characters`
      : `Limited to ${MAX_MESSAGE_LENGTH} characters`;
  }

  @computed('selectedCareTypesOptions.[]')
  get careTypesCheckboxes() {
    return this.mapOptionsToCheckboxes(this.careTypesOptions, this.careTypesCollection);
  }

  @computed(
    'featureBookingWidgetMedicationQuestion',
    'featureExpandPrescreenerQuestions',
    'isProfessionValid'
  )
  get canDisplayMedicationQuestions() {
    let {
      featureBookingWidgetMedicationQuestion,
      featureExpandPrescreenerQuestions,
      isProfessionValid,
    } = this;
    return (
      (featureBookingWidgetMedicationQuestion || featureExpandPrescreenerQuestions) &&
      isProfessionValid
    );
  }

  @computed('globalProfessionName')
  get isProfessionValid() {
    let { globalProfessionName } = this;

    return [PSYCHIATRY, PSYCHIATRY_MENTAL_HEALTH_NURSING].includes(globalProfessionName);
  }

  @computed('isReasonsForVisitEnabled', 'featureExpandPrescreenerQuestions', 'hasMonarchProfession')
  get canDisplayReasonForVisit() {
    let { isReasonsForVisitEnabled, featureExpandPrescreenerQuestions, hasMonarchProfession } =
      this;
    return isReasonsForVisitEnabled || (featureExpandPrescreenerQuestions && hasMonarchProfession);
  }
}
