import { capitalize } from '@ember/string';
import { service } from '@ember/service';
import Helper from '@ember/component/helper';

export const DAY_MILLISECONDS = 1000 * 60 * 60 * 24;

export default class formatMessagingTimestamp extends Helper {
  @service intl;

  compute([timestamp]) {
    if (!timestamp) return;

    const daysDifference = Math.round((timestamp - new Date().getTime()) / DAY_MILLISECONDS);

    if (daysDifference === 0) {
      const dtf = new Intl.DateTimeFormat(this.intl.primaryLocale, {
        hour: 'numeric',
        minute: 'numeric',
      });
      return dtf.format(timestamp);
    } else if (daysDifference === -1) {
      const rtf = new Intl.RelativeTimeFormat(this.intl.primaryLocale, { numeric: 'auto' });
      return capitalize(rtf.format(daysDifference, 'day'));
    } else {
      const dtf = new Intl.DateTimeFormat(this.intl.primaryLocale, {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric',
      });
      return dtf.format(timestamp);
    }
  }
}
