const SEGMENT_TRACKING_HEADER = 'Segment-Tracking-Anonymous-Id';

export default function getSegmentHeaders(analyticsService) {
  let headers = {};

  if (analyticsService.anonymousId) {
    headers[SEGMENT_TRACKING_HEADER] = analyticsService.anonymousId;
  }

  return headers;
}
