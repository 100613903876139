/* import __COLOCATED_TEMPLATE__ from './home-hero-unit.hbs'; */
import { action, computed } from '@ember/object';
import { service } from '@ember/service';
import { tagName } from '@ember-decorators/component';
import Component from '@ember/component';
import classic from 'ember-classic-decorator';

@classic
@tagName('')
export default class HomeHeroUnit extends Component {
  @service currentPractice;
  @service websiteWidget;

  @action
  showContactForm() {
    this.websiteWidget.openContactWidgetTask.perform();
  }

  @computed('currentPractice.defaultOffice.{city,state}')
  get hasPhysicalLocations() {
    let { city, state } = this.currentPractice.get('defaultOffice') || {};
    return !!(city && state);
  }
}
